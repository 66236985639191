import Baobab from 'baobab';
import find from 'lodash.find';

import USER_STRUCT from '../entities/user';
import AGENT_STRUCT from '../entities/agent';
import ENTITY_STRUCT from '../entities';
import TOP_STRUCT from '../';
import CLIENT_STRUCT from '../entities/client';
import INSTITUTION_STRUCT from '../entities/institution';
import POSITION_STRUCT from '../entities/position';
import { getById } from '../../../utils';
import MEETING_STRUCT from '../entities/meeting';
import INVEST_STRUCT from '../entities/invest';
import { CALCULATED_CLIENT_STRUCT } from './client';
import CALCULATED_MEETING_STRUCT from './meeting';
import REPORT_STRUCT from '../entities/report';
import AGENT_RATING_STRUCT from './agentRating';
import AGENT_QUEUE_STRUCT from './agentQueue';
import TIMESLOT_STRUCT from '../entities/timeslots';
import { LOGS, getInitialState as getLogInitialState } from './log';
import { GAMES, GAME, getInitialState as getGamesInitialState } from './game';
import { getDate, getTime, getUTCDate } from '../../../utils/time';

const monkey = Baobab.monkey;

const CALCULATED_STRUCT = {
  LOGS,
  GAMES,
  GAME,
  CLIENT_MEETINGS: 'client_meetings',
  AGENT_MEETINGS: 'curr_meetings',
  CURRENT_CLIENTS: 'curr_clients',
  GAMES_TEAMS: 'game_teams',
  AGENT_RATING: 'agent_rating',
  AGENT_QUEUE: 'agent_queue',
};

export default CALCULATED_STRUCT;

export const initialState = () => ({
  ...getLogInitialState(),
  ...getGamesInitialState(),
  [CALCULATED_STRUCT.CURRENT_CLIENTS]: monkey(
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.CLIENT],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.POSITION],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.INSTITUTION],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.MEETING],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.INVEST],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.EVENT],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.ME, AGENT_STRUCT.ID],
    function(clients, posts, institution, meetings, invests, events, agentId) {
      return clients.map(client => {
        const { ...resClient } = client;

        resClient[CALCULATED_CLIENT_STRUCT.POSITION] = getById(
          posts,
          resClient[CLIENT_STRUCT.POSITION_ID],
        );
        resClient[CALCULATED_CLIENT_STRUCT.INSTITUTION] = getById(
          institution,
          resClient[CLIENT_STRUCT.INSTITUTION_ID],
        );

        const agentMeetings = getAgentMeetings(agentId, meetings);
        resClient[CALCULATED_CLIENT_STRUCT.MEETINGS] = agentMeetings.filter(
          meeting =>
            meeting[MEETING_STRUCT.CUSTOMER_ID] === resClient[CLIENT_STRUCT.ID],
        );

        resClient[CALCULATED_CLIENT_STRUCT.INVESTS] = invests
          .filter(
            invest =>
              invest[MEETING_STRUCT.CUSTOMER_ID] === client[CLIENT_STRUCT.ID] &&
              invest[INVEST_STRUCT.REPRESENTATIVE_ID] === agentId,
          )
          .map((
            invest, // invests object is not extensible
          ) => {
            const relatedEvent = getById(
              events,
              invest[INVEST_STRUCT.EVENT_ID],
            );
            return { ...invest, event: relatedEvent };
          });

        return resClient;
      });
    },
  ),
  [CALCULATED_STRUCT.AGENT_MEETINGS]: monkey(
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.ME, USER_STRUCT.ID],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.MEETING],
    getAgentMeetings,
  ),
  [CALCULATED_STRUCT.CLIENT_MEETINGS]: monkey(
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.ME, 0, USER_STRUCT.ID],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.MEETING],
    getClientMeetings,
  ),
  [CALCULATED_STRUCT.AGENT_RATING]: monkey(
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.REPORTS],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.AGENT],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.SIX_IBS_PARAM],
    (reports, agents, params) => {
      const rating = {};

      agents.forEach(agent => {
        rating[agent[AGENT_STRUCT.ID]] = {
          [AGENT_RATING_STRUCT.GRADES]: params.map(() => 0),
          [AGENT_RATING_STRUCT.GRADE_SUM]: 0,
          [AGENT_RATING_STRUCT.NAME]: agent[AGENT_STRUCT.NAME],
          [AGENT_RATING_STRUCT.GOAL]: agent[AGENT_STRUCT.GOAL],
        };
      });

      reports.forEach(report => {
        const grades = report[REPORT_STRUCT.GRADES];
        const entry = rating[report[REPORT_STRUCT.REPRESENTATIVE_ID]];

        if (!entry) {
          return;
        }

        grades.forEach((grade, i) => {
          entry[AGENT_RATING_STRUCT.GRADES][i] += grade;
          entry[AGENT_RATING_STRUCT.GRADE_SUM] += grade;
        });
      });

      const arr = Array.from(Object.keys(rating), key => ({
        [AGENT_RATING_STRUCT.AGENT_ID]: Number(key),
        ...rating[key],
      }));

      arr.sort(
        (a, b) =>
          b[AGENT_RATING_STRUCT.GRADE_SUM] - a[AGENT_RATING_STRUCT.GRADE_SUM],
      );
      return arr;
    },
  ),
  [CALCULATED_STRUCT.AGENT_QUEUE]: monkey(
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.TIMESLOTS],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.REPORTS],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.CLIENT],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.INSTITUTION],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.POSITION],
    (timeslots, reports, clients, institutions, positions) => {
      const queueLengths = {};

      clients.forEach(client => {
        queueLengths[client[CLIENT_STRUCT.ID]] = {
          [AGENT_QUEUE_STRUCT.QUEUE_LENGTH]: 0,
          [AGENT_QUEUE_STRUCT.INSTITUTION_NAME]:
            getById(institutions, client[CLIENT_STRUCT.INSTITUTION_ID])[
              INSTITUTION_STRUCT.NAME
            ] || '',
          [AGENT_QUEUE_STRUCT.POSITION_NAME]:
            getById(positions, client[CLIENT_STRUCT.POSITION_ID])[
              POSITION_STRUCT.NAME
            ] || '',
          [AGENT_QUEUE_STRUCT.NAME]: client[CLIENT_STRUCT.NAME],
        };
      });
      timeslots.forEach(timeslot => {
        const id = timeslot[TIMESLOT_STRUCT.CUSTOMER_ID];

        if (
          timeslot[TIMESLOT_STRUCT.MEETING_ID] !== null &&
          !find(reports, {
            [REPORT_STRUCT.MEETING_ID]: timeslot[TIMESLOT_STRUCT.MEETING_ID],
          })
        ) {
          const entry = queueLengths[id];
          if (entry) {
            entry[AGENT_QUEUE_STRUCT.QUEUE_LENGTH]++;
          }
        }
      });

      const arr = Array.from(Object.keys(queueLengths), key => ({
        ...queueLengths[key],
        [AGENT_QUEUE_STRUCT.CLIENT_ID]: Number(key),
      }));

      arr.sort(
        (a, b) =>
          b[AGENT_QUEUE_STRUCT.QUEUE_LENGTH] -
          a[AGENT_QUEUE_STRUCT.QUEUE_LENGTH],
      );
      return arr;
    },
  ),
});

export function getClientMeetings(clientId, meetings) {
  const currMeetings = meetings
    .filter(meeting => {
      return (
        meeting[MEETING_STRUCT.REPRESENTATIVE_ID] !== null &&
        meeting[MEETING_STRUCT.CUSTOMER_ID] === clientId
      );
    })
    .map(meetingsFormat);

  return currMeetings.sort(meetingsSort);
}

export function getAgentMeetings(agentId, meetings) {
  let currMeetings = meetings
    .filter(meeting => {
      // workaround. When any meeting with other agent is finished excess data is send to every agent, we have to filter it here
      return (
        !meeting[MEETING_STRUCT.REPRESENTATIVE_ID] ||
        meeting[MEETING_STRUCT.REPRESENTATIVE_ID] === agentId
      );
    })
    .map(meetingsFormat);

  return currMeetings.sort(meetingsSort);
}

function meetingsSort(a, b) {
  const timeA = getUTCDate(a[TIMESLOT_STRUCT.START]).getTime(),
    timeB = getUTCDate(b[TIMESLOT_STRUCT.START]).getTime();

  return timeA - timeB;
}

function meetingsFormat(item) {
  const start = item[TIMESLOT_STRUCT.START];

  return {
    ...item,
    [CALCULATED_MEETING_STRUCT.TIME]: getTime(start),
    [CALCULATED_MEETING_STRUCT.DATE]: getDate(start),
  };
}
