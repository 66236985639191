import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { PARAMS } from '../../../../components/form/custom-select';
import Tabs from '../../../../components/tabs';

import SIXIBS_PARAM_STRUCT from '../../../../store/struct/entities/sixIbsParam';

import styles from '../index.module.scss';

const HEADERS = [
  'Наблюдаемое поведение (критерии оценки)',
  'Соответствие ожиданиям',
];
const OPTIONS = [
  {
    [PARAMS.ID]: 1,
    [PARAMS.VALUE]: 1,
    [PARAMS.TITLE]: 1.1,
    [PARAMS.DESC]: 'Не соответствует',
    [PARAMS.COLOR]: '219, 238, 243',
  },
  // {
  //   [PARAMS.ID]: 2,
  //   [PARAMS.VALUE]: 2.1,
  //   [PARAMS.TITLE]: 1.2,
  //   [PARAMS.DESC]: 'Не соответствует',
  //   [PARAMS.COLOR]: '219, 238, 243',
  // },
  // {
  //   [PARAMS.ID]: 3,
  //   [PARAMS.VALUE]: 3.1,
  //   [PARAMS.TITLE]: 1.3,
  //   [PARAMS.DESC]: 'Не соответствует',
  //   [PARAMS.COLOR]: '219, 238, 243',
  // },
  {
    [PARAMS.ID]: 2,
    [PARAMS.VALUE]: 2,
    [PARAMS.TITLE]: 2.1,
    [PARAMS.DESC]: 'Частично соответствует',
    [PARAMS.COLOR]: '235, 241, 222',
  },
  // {
  //   [PARAMS.ID]: 5,
  //   [PARAMS.VALUE]: 5.2,
  //   [PARAMS.TITLE]: 2.2,
  //   [PARAMS.DESC]: 'Частично соответствует',
  //   [PARAMS.COLOR]: '235, 241, 222',
  // },
  // {
  //   [PARAMS.ID]: 6,
  //   [PARAMS.VALUE]: 6.2,
  //   [PARAMS.TITLE]: 2.3,
  //   [PARAMS.DESC]: 'Частично соответствует',
  //   [PARAMS.COLOR]: '235, 241, 222',
  // },
  {
    [PARAMS.ID]: 3,
    [PARAMS.VALUE]: 3,
    [PARAMS.TITLE]: 3.1,
    [PARAMS.DESC]: 'Полностью соответствует',
    [PARAMS.COLOR]: '245, 245, 149',
  },
  // {
  //   [PARAMS.ID]: 8,
  //   [PARAMS.VALUE]: 8.3,
  //   [PARAMS.TITLE]: 3.2,
  //   [PARAMS.DESC]: 'Полностью соответствует',
  //   [PARAMS.COLOR]: '245, 245, 149',
  // },
  // {
  //   [PARAMS.ID]: 9,
  //   [PARAMS.VALUE]: 9.3,
  //   [PARAMS.TITLE]: 3.3,
  //   [PARAMS.DESC]: 'Полностью соответствует',
  //   [PARAMS.COLOR]: '245, 245, 149',
  // },
  {
    [PARAMS.ID]: 4,
    [PARAMS.VALUE]: 4,
    [PARAMS.TITLE]: 4.1,
    [PARAMS.DESC]: 'Превосходит',
    [PARAMS.COLOR]: '253, 234, 217',
  },
  // {
  //   [PARAMS.ID]: 11,
  //   [PARAMS.VALUE]: 11.4,
  //   [PARAMS.TITLE]: 4.2,
  //   [PARAMS.DESC]: 'Превосходит',
  //   [PARAMS.COLOR]: '253, 234, 217',
  // },
  // {
  //   [PARAMS.ID]: 12,
  //   [PARAMS.VALUE]: 12.4,
  //   [PARAMS.TITLE]: 4.3,
  //   [PARAMS.DESC]: 'Превосходит',
  //   [PARAMS.COLOR]: '253, 234, 217',
  // },
];

const Estimates = ({ onChange, data }) => {
  const tableData = useMemo(() => {
    return data.map(param => ({
      id: param[SIXIBS_PARAM_STRUCT.ID],
      name: param[SIXIBS_PARAM_STRUCT.TITLE],
    }));
  }, [data]);

  const [activeTab, setActiveTab] = useState(0);
  const [state, setState] = useState(
    tableData.reduce(
      (result, param) => ({
        ...result,
        [param.id]: 0,
      }),
      {},
    ),
  );
  const getHandlerForGrade = useCallback(
    id => () => {
      setState({ ...state, [activeTab + 1]: id });
      setTimeout(() => {
        if (activeTab + 1 < data.length) {
          setActiveTab(activeTab + 1);
        }
      }, 500);
    },
    [state, setState, activeTab, setActiveTab],
  );

  useEffect(() => {
    onChange(state);
  }, [state, onChange]);
  return (
    <Tabs
      tabs={data}
      state={state}
      activeTab={activeTab}
      setActiveTab={setActiveTab}
      headers={HEADERS}
    >
      <div className={styles.six_ibs_params_container}>
        {OPTIONS.map((o, index) => (
          <button
            key={`6ibs-params-${index}`}
            style={{
              background: `rgb(${o[PARAMS.COLOR]})`,
              marginBottom: '8px',
            }}
            onClick={getHandlerForGrade(o[PARAMS.ID])}
            className={classnames(styles.six_ibs_params, {
              [styles.six_ibs_params_selected]:
                state[activeTab + 1] && o[PARAMS.ID] === state[activeTab + 1],
            })}
          >
            <span className={styles.grade}>{o[PARAMS.DESC]}</span>
            {/* <span>{o[PARAMS.TITLE]}</span> */}
          </button>
        ))}
      </div>
    </Tabs>
  );
};

Estimates.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default Estimates;
