import React, { Fragment, PureComponent, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import includes from 'lodash.includes';

import Button from '../button';
import InputText from '../text-input';
import InputNumber from '../number-input';
import Select from '../form/select';
import CustomSelect from '../form/custom-select';
import Checkboxes from '../form/checkboxes';

import styles from './index.module.scss';
import Checkbox from '../checkbox/index';

export const TYPES = {
  TEXT: 'text',
  DESC: 'desc',
  BUTTON: 'button',
  INPUT: 'input',
  INPUT_NUMBER_LIST: 'input_number_list',
  CHECKBOX: 'checkbox',
  CHECKBOX_LIST: 'checkbox-list',
  SELECT: 'select',
  CUSTOM_SELECT: 'custom_select',
  LABEL: 'label',
  CONDITIONAL: 'conditional',
};

const TableText = ({ config, data, className, rowIndex }) => {
  return (
    <div className={className} style={config.style}>
      {config.getValue(data, rowIndex)}
    </div>
  );
};

TableText.propTypes = {
  config: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
  rowIndex: PropTypes.number.isRequired,
  placeholder: PropTypes.string,
};
TableText.defaultProps = {
  className: '',
};

const TableDescription = ({ config, data, className }) => {
  return (
    <div className={`${styles.description} ${className}`}>
      {config.getValue(data)}
    </div>
  );
};
TableDescription.propTypes = {
  config: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  className: PropTypes.string,
};
TableDescription.defaultProps = {
  className: '',
};

const TableLabel = ({ config, data, className, htmlFor }) => {
  return (
    <label className={`${styles.description} ${className}`} htmlFor={htmlFor}>
      {config.getValue(data)}
    </label>
  );
};

const TableButton = ({ config, data, disabled, rowIndex, className }) => {
  const handleClick = useCallback(() => config.onClick(data, rowIndex), [
    data,
    config,
    rowIndex,
  ]);

  if (config.render) {
    const CustomRender = config.render;

    return (
      <CustomRender
        onClick={handleClick}
        theme={config.theme}
        disabled={disabled}
        className={className}
      />
    );
  }

  return (
    <Button
      onClick={handleClick}
      theme={config.theme}
      disabled={disabled}
      min={config.min}
    >
      {config.text}
    </Button>
  );
};

const TableInput = ({
  config,
  data,
  inputType,
  placeholder,
  min,
  max,
  style,
  disabled,
}) => {
  const handleChange = useCallback(value => config.onChange(data, value), [
    data,
    config,
  ]);
  const Component = inputType === 'number' ? InputNumber : InputText;

  return (
    <>
      <Component
        onChange={handleChange}
        value={config.getValue(data)}
        disabled={disabled}
        placeholder={placeholder}
        min={min}
        max={max}
      />
      {config.getPostfix && <span>{config.getPostfix(data)}</span>}
    </>
  );
};

const TableInputNumberList = ({
  config,
  data,
  inputType,
  placeholder,
  min,
  max,
  style,
  disabled,
}) => {
  const values = config.getValue(data);
  const resultValues = [];

  const handleChange = useCallback(
    (value, index) => {
      resultValues[index] = value;
      return config.onChange(data, resultValues);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config, data],
  );

  return (
    <div className={styles.inputListContainer}>
      {values.map((_, index) => (
        <InputNumber
          key={index.toString()}
          onChange={handleChange}
          value={config.getValue(data)}
          disabled={disabled}
          placeholder={placeholder}
          min={min}
          max={max}
          index={index}
        />
      ))}
    </div>
  );
};

const TableCheckbox = ({ config, data, id }) => {
  const handleChange = useCallback(value => config.onChange(data, value), [
    data,
    config,
  ]);

  return (
    <Checkbox
      id={id}
      onChange={checked => {
        handleChange(checked);
      }}
      checked={config.getValue(data)}
    />
  );
};

const TableCheckboxList = ({ config, data }) => {
  const [checked, setChecked] = useState(null);

  const handleChange = useCallback(
    value => {
      setChecked(value);
      return config.onChange(data, value);
    },
    [data, config],
  );

  const options = config.options || config.getOptions(data);

  return (
    <Checkboxes options={options} value={checked} onChange={handleChange} />
  );
};

const TableSelect = ({ config, data, ...props }) => {
  const handleChange = useCallback(
    (id, index) => config.onChange(data, id, index),
    [data, config],
  );

  const options = config.options || config.getOptions(data);

  return (
    <>
      {config.getPrefix && (
        <span className={styles.prefix}>{config.getPrefix(data)}</span>
      )}
      <Select
        options={options}
        onChange={handleChange}
        onReset={config.onReset}
        disabled={props.disabled}
        className={props.className}
        placeholder={config.placeholder || props.placeholder}
        value={config.value || (config.getValue ? config.getValue(data) : null)}
        selectablePlaceholder={
          config.selectablePlaceholder || props.selectablePlaceholder
        }
        resetAfterSelection={config.resetAfterSelection}
      />
    </>
  );
};

const TableCustomSelect = ({ config, data, ...props }) => {
  const handleChange = useCallback(
    (id, index) => config.onChange(data, id, index),
    [data, config],
  );

  const options = config.options || config.getOptions(data);

  return (
    <>
      {config.getPrefix && (
        <span className={styles.prefix}>{config.getPrefix(data)}</span>
      )}
      <CustomSelect
        options={options}
        onChange={handleChange}
        position={props.rowIndex > config.rowsCount - 4 ? 'top' : 'bottom'}
        placeholder={config.placeholder || props.placeholder}
      />
    </>
  );
};

const TableConditionalComponent = ({ config, data: rowData, rowIndex }) => {
  const index = config.getComponentIndex(rowData);
  if (isNaN(index)) {
    return null;
  }
  const conditionalConfig = config.components[index];

  return (
    <TableCellItems
      {...{ rowData, itemsConfigs: conditionalConfig, rowIndex }}
    />
  );
};

const TABLE_ITEMS = {
  [TYPES.TEXT]: TableText,
  [TYPES.DESC]: TableDescription,
  [TYPES.BUTTON]: TableButton,
  [TYPES.INPUT]: TableInput,
  [TYPES.INPUT_NUMBER_LIST]: TableInputNumberList,
  [TYPES.SELECT]: TableSelect,
  [TYPES.CUSTOM_SELECT]: TableCustomSelect,
  [TYPES.CHECKBOX]: TableCheckbox,
  [TYPES.CHECKBOX_LIST]: TableCheckboxList,
  [TYPES.LABEL]: TableLabel,
  [TYPES.CONDITIONAL]: TableConditionalComponent,
};

function TableCellItems({ rowData, itemsConfigs, rowIndex }) {
  itemsConfigs = Array.isArray(itemsConfigs) ? itemsConfigs : [itemsConfigs];

  return itemsConfigs.map((item, i) => {
    const TableItem =
      TABLE_ITEMS[item.getType ? item.getType(rowData) : item.type] || null;

    const props = item.getProps ? item.getProps(rowData) : {};

    return (
      <TableItem
        key={i}
        rowIndex={rowIndex}
        config={item}
        data={rowData}
        {...props}
      />
    );
  });
}

export default class Table extends PureComponent {
  static propTypes = {
    config: PropTypes.array.isRequired,
    rowConfig: PropTypes.object,
    headerConfig: PropTypes.array,
    groupsConfig: PropTypes.array,
    data: PropTypes.array.isRequired,
    selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onClick: PropTypes.func,
    className: PropTypes.string,
    keyName: PropTypes.string,
    filters: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.number), // Can be array with indexes of headers with filter
      PropTypes.bool, // if true every header is a filter
    ]),
  };

  static defaultProps = {
    onClick: () => {},
    selected: null,
    groupsConfig: null,
    className: '',
    keyName: 'id',
    rowConfig: {},
    headerConfig: [],
  };

  constructor(props) {
    super(props);
    this.state = {
      filters: Array.from(new Array(props.headers.length), () => null),
    };
  }

  get headers() {
    const {
      headers,
      filters,
      config,
      headerConfig,
      data: tableData,
    } = this.props;

    return headers.map((header, i) => {
      const headerRequireFilter =
        filters &&
        (typeof filters === 'boolean' ||
          (Array.isArray(filters) && includes(filters, i)));

      if (!headerRequireFilter) {
        return <th key={i}>{header}</th>;
      }

      const cellValueGetter = config[i].getValue;
      const headerOptions = [];
      const uniqueValues = [];

      tableData.forEach(rowData => {
        const cellValue = cellValueGetter(rowData);
        if (includes(uniqueValues, cellValue)) {
          return;
        }
        uniqueValues.push(cellValue);

        headerOptions.push({
          id: cellValue,
          title: cellValue,
          disabled: false,
        });
      });

      const handleChange = ((columnIndex, rowData, filterValue) => {
        this.setState(prevState => {
          const filters = prevState.filters.slice();
          filters[columnIndex] = filterValue;
          return { filters };
        });
      }).bind(null, i);

      const handleReset = (columnIndex => {
        this.setState(prevState => {
          const filters = prevState.filters.slice();
          filters[columnIndex] = null;
          return { filters };
        });
      }).bind(null, i);

      const selectConfig = {
        type: TYPES.SELECT,
        onChange: handleChange,
        onReset: handleReset,
        options: headerOptions,
        placeholder: header,
        selectablePlaceholder: true,
        min: config[i].min,
      };
      const props = (headerConfig[i] && headerConfig[i].props) || {};

      return (
        <th key={i}>
          <TableSelect
            data={this.props.data}
            config={selectConfig}
            {...props}
          />
        </th>
      );
    });
  }

  get rowGroups() {
    const { groupsConfig, data, keyName } = this.props;

    return data.map((rowData, i) => (
      <Fragment key={rowData[keyName]}>
        <tr className={styles.group}>
          {this.getCells(groupsConfig, rowData, i)}
        </tr>
        {this.getRows(rowData.data)}
      </Fragment>
    ));
  }

  getRows = data => {
    const {
      selected,
      config,
      rowConfig,
      keyName,
      onClick,
      filters,
    } = this.props;
    return data.map((rowData, i) => {
      const className = rowConfig.getClassName
        ? rowConfig.getClassName(rowData)
        : '';

      if (filters) {
        // todo add support of cells with multiple table components
        const rowConformFilters = config.every((cellConfig, i) => {
          // Cells with a button does not have a value
          if (!cellConfig.getValue) {
            return true;
          }
          const cellValue = cellConfig.getValue(rowData); // todo config item can be array
          return (
            this.state.filters[i] === null ||
            this.state.filters[i] === cellValue
          );
        });

        if (!rowConformFilters) {
          return undefined;
        }
      }
      const key = rowConfig.getKey
        ? rowConfig.getKey(rowData)
        : rowData.key || rowData[keyName] || i;

      return (
        <tr
          key={key}
          className={`${
            rowData.id === selected ? styles.selected : ''
          } ${className}`}
          onClick={onClick.bind(this, rowData)}
        >
          {this.getCells(config, rowData, i)}
        </tr>
      );
    });
  };

  getCells = (config, rowData, rowIndex) => {
    return config.map((itemsConfigs, cellIndex) => {
      return (
        <td key={cellIndex} className={itemsConfigs.min ? styles.min : ''}>
          <TableCellItems {...{ rowData, itemsConfigs, rowIndex }} />
        </td>
      );
    });
  };

  render() {
    const { className, groupsConfig, data } = this.props;
    // don't make headings sticky for Edge browser because of the bug (a text position breaks if the container has scroll)
    const isEdge = /Edge/.test(navigator.userAgent);

    return (
      <div className={`${styles.table} ${className}`}>
        <table>
          <thead className={isEdge ? '' : styles.sticky}>
            <tr>{this.headers}</tr>
          </thead>
          <tbody>{groupsConfig ? this.rowGroups : this.getRows(data)}</tbody>
        </table>
      </div>
    );
  }
}
