const STRUCT = {
  ID: 'id',
  CLIENT_INFO: 'clientInfo',
  MEETING: 'meeting',
  PRESCRIPTION: 'prescription',
  GRADES: 'grades',
  GRADES_REP: 'gradesRep',
  COMMENT: 'comment',
  COMMENT_REP: 'comment_rep',
  QUESTION_FORM_RESULT: 'question_form_result',
  STATUS: 'status',
};

export default STRUCT;
