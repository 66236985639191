import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import padStart from 'lodash.padstart';

import styles from './index.module.scss';

function pad(str) {
  return padStart(str, 2, '0');
}

const REFRESH_INTERVAL = 1000;

const Timer = props => {
  const { onComplete, time, isHoursNeeded, color } = props;
  let [restOfTime, setRestOfTime] = useState(time);
  let startTime = useRef();
  let timerRef = useRef();

  useEffect(() => {
    startTime.current = Date.now();
    setRestOfTime(time); // allows to restart the timer by changing time property from outside of the component
  }, [time]);

  useEffect(() => {
    if (restOfTime > 0) {
      timerRef.current = setTimeout(() => {
        const rest = time - Math.round((Date.now() - startTime.current) / 1000);
        setRestOfTime(rest > 0 ? rest : 0);
      }, REFRESH_INTERVAL);
    } else {
      onComplete();
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  });

  const hours = String(Math.floor(restOfTime / 3600));
  const minutes = String(Math.floor((restOfTime - hours * 3600) / 60));
  const seconds = String(restOfTime - hours * 3600 - minutes * 60);

  return (
    <div className={styles.container} style={color ? { color: color } : null}>
      <span>{`${isHoursNeeded ? pad(hours) + ':' : ''}${pad(minutes)}:${pad(
        seconds,
      )}`}</span>
    </div>
  );
};

export default Timer;

Timer.propTypes = {
  time: PropTypes.number.isRequired,
  onComplete: PropTypes.func,
};
Timer.defaultProps = {
  onComplete: () => {},
};
