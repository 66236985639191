import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import styles from './index.module.scss';

const Tabs = ({ tabs, state, activeTab, setActiveTab, headers, children }) => {
  const [tabRefs, setTabRefs] = useState([]);
  useEffect(() => {
    setTabRefs(
      Array(tabs.length)
        .fill(null)
        .map((_, index) => tabRefs[index] || createRef()),
    );
  }, [tabs.length]);
  useEffect(() => {
    if (tabRefs[activeTab] && tabRefs[activeTab].current) {
      tabRefs[activeTab].current.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }, [activeTab]);

  return (
    <div className={styles.root}>
      <div className={styles.tabs_header}>
        {headers.map((h, index) => (
          <div
            style={{ paddingLeft: index === 0 ? '12px' : '29px' }}
            key={`tabs-header-${h}`}
          >
            {h}
          </div>
        ))}
      </div>

      <div className={styles.tabs}>
        <div className={styles.tabs_tabs}>
          {tabs.map((t, index) => (
            <button
              ref={tabRefs[index]}
              key={`tab-${t.id}`}
              onClick={() => setActiveTab(index)}
              className={classnames(styles.tabs_title, {
                [styles.tabs_title_active]: activeTab === index,
                [styles.tabs_title_answered]: !state[index + 1],
              })}
            >
              <span>{t.title}</span>
            </button>
          ))}
        </div>

        <div className={styles.tabs_content}>{children}</div>
      </div>
    </div>
  );
};

Tabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
    }),
  ).isRequired,
  activeTab: PropTypes.number.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default Tabs;
