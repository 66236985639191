import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import styles from './index.module.scss';

const NumberText = props => {
  const { onChange, min, max, index } = props;
  const handleChange = useCallback(
    e => {
      onChange(e.target.value, index);
    },
    [index, onChange],
  );

  const validation = props => {
    if (min && props.target.value < min) {
      props.target.setCustomValidity(`Минимальное значение ${min}`);
    } else if (max && props.target.value > max) {
      props.target.setCustomValidity(`Максимальное значение ${max}`);
    } else {
      // input is fine -- reset the error message
      props.target.setCustomValidity('');
    }
    props.target.reportValidity();
  };

  return (
    <input
      placeholder={props.placeholder}
      className={`${styles.input} ${props.className}`}
      type='number'
      defaultValue={props.value}
      onChange={handleChange}
      disabled={props.disabled}
      min={min}
      max={max}
      onInput={validation}
    />
  );
};

export default NumberText;

NumberText.propTypes = {
  onChange: PropTypes.func,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

NumberText.defaultProps = {
  onChange: () => null,
  className: '',
  disabled: false,
};
