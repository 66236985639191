import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { branch } from 'baobab-react/higher-order';

import Timer from '../../../components/timer';
import Dialog from '../../../components/dialog';
import Confirmation from './confirmation';
import Grades from './grades';
import Comment from './comment';
import PrescriptionForm1 from './prescription';
import PrescriptionForm2 from './prescription2';

import { MEETING_STATUSES } from '../../../store/struct/entities/meeting';
import GAME_STRUCT from '../../../store/struct/entities/game';
import {
  PRESCRIPTION_FORM_1,
  PRESCRIPTION_FORM_2,
} from '../../../store/struct/calculated/game';
import {
  currentGameSelector,
  agentsSelector,
  meSelector,
  sixIbsParamSelector,
  positionsSelector,
  institutionsSelector,
  currentClientsSelector,
} from '../../../store/struct/selectors';

import styles from './index.module.scss';
import { getUTCDate } from '../../../utils/time';

const BTNS = {
  BACK: 'back',
  NEXT: 'next',
  SAVE: 'save',
  START: 'start',
  STOP: 'stop',
};

const STEPS = {
  CONFIRMATION: 0,
  PREVIOUS_COMMENTS: 1,
  PRESCRIPTION_FORM_1: 2,
  PRESCRIPTION_FORM_2: 3,
  GRADES: 4,
  QUESTION_FORM: 5,
  COMMENT: 6,
  END_CONFIRMATION: 7,
};

const Step = ({ step, current, children }) => (
  <div className={step === current ? '' : styles.hidden}>{children}</div>
);

const MeetingDialog = ({
  game,
  clients,
  sixIbsParam,
  clientId,
  onEnd,
  onStart,
  onCancelStart,
  meetingStart,
  meetingStartTime,
  meetingStartDate,
  actualStart,
  isMeetingStarted,
}) => {
  // Get current UTC timestamp
  const nowUTCTime = getUTCDate(new Date()).getTime();
  // Convert actual time of meeting start to Date
  const actualStartDate = getUTCDate(actualStart);

  // Count time of meeting end
  const endMeetingTime = actualStartDate.setMinutes(
    actualStartDate.getMinutes() + game[GAME_STRUCT.MEETING_LENGTH],
    //+ game[GAME_STRUCT.MEETING_BREAK],
  );
  // Get actual time until the end of meeting
  const actualMeetingTime = Math.trunc((endMeetingTime - nowUTCTime) / 1000);

  const MEETING_TIME = game[GAME_STRUCT.MEETING_LENGTH] * 60;
  const [step, setStep] = useState(0);
  const [status, setStatus] = useState(null);
  const [gradesState, setGradesState] = useState({});
  const [prescriptionFormResult, setPrescriptionForm] = useState([]);
  const [trigger, setTrigger] = useState(Date.now());
  const [comment, setComment] = useState('');
  const stepOrders = useMemo(() => {
    const arr = [STEPS.COMMENT, STEPS.END_CONFIRMATION];
    setStatus(MEETING_STATUSES.done);

    if (sixIbsParam) {
      arr.unshift(STEPS.GRADES);
    }

    if (game && game[PRESCRIPTION_FORM_1]) {
      arr.unshift(STEPS.PRESCRIPTION_FORM_1);
    }

    if (game && game[PRESCRIPTION_FORM_2]) {
      arr.unshift(STEPS.PRESCRIPTION_FORM_2);
    }

    arr.unshift(STEPS.CONFIRMATION);

    return arr;
  }, [sixIbsParam]);

  useEffect(() => {
    if (isMeetingStarted) {
      setStep(1);
    }
  }, [isMeetingStarted]);

  const handleClick = useCallback(
    id => {
      if (id === BTNS.START) {
        onStart();
        setStep(step + 1);
      }
      if (id === BTNS.BACK) {
        setStep(step - 1);
      }
      if (id === BTNS.NEXT) {
        setStep(step + 1);
      }
      if (id === BTNS.SAVE) {
        onEnd({ prescriptionFormResult, gradesState, comment, status });
      }
    },
    [
      step,
      onStart,
      prescriptionFormResult,
      onEnd,
      gradesState,
      comment,
      status,
    ],
  );
  const buttonsConfig = useMemo(() => {
    let btns = [];

    if (step === 0) {
      btns.push({
        id: BTNS.START,
        title: 'Начать встречу',
      });
    }
    if (step > 1) {
      btns.push({
        id: BTNS.BACK,
        title: 'Назад',
      });
    }
    if (step < stepOrders.length - 1 && step > 0) {
      btns.push({
        id: BTNS.NEXT,
        title: 'Далее',
        disabled:
          (stepOrders[step] === STEPS.PRESCRIPTION_FORM_1 &&
            prescriptionFormResult.some(answer => {
              return answer.some(value => {
                return value < 0 ? true : value.length === 0 ? true : false;
              });
            })) ||
          (stepOrders[step] === STEPS.PRESCRIPTION_FORM_2 &&
            prescriptionFormResult.some(answer => {
              return answer['res'].some(value => {
                return value < 0 ? true : false;
              });
            })) ||
          (stepOrders[step] === STEPS.GRADES &&
            Object.values(gradesState).some(grade => grade === 0)),
      });
    }
    if (step === stepOrders.length - 1) {
      btns.push({
        id: BTNS.SAVE,
        title: 'Сохранить',
      });
    }
    if (status === MEETING_STATUSES.canceled) {
      btns = [];
      btns.push({
        id: BTNS.SAVE,
        title: 'Сохранить',
      });
    }
    return btns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [step, stepOrders, prescriptionFormResult, gradesState, status, trigger]);

  const currClient = clients.find(v => v.id === clientId);

  return (
    <Dialog
      title={
        step > 0 ? (
          <>
            <div>Встреча с: {currClient.name}</div>
            <Timer
              time={
                actualStart
                  ? actualMeetingTime > 0
                    ? actualMeetingTime
                    : 0
                  : MEETING_TIME
              }
            />
          </>
        ) : null
      }
      onCancel={
        {
          0: onCancelStart,
        }[step]
      }
      cancelTitle={'Отменить начало'}
      buttons={buttonsConfig}
      onClick={handleClick}
    >
      <Step step={STEPS.CONFIRMATION} current={stepOrders[step]}>
        <Confirmation
          agentName={currClient.name}
          meetingStartTime={meetingStartTime}
          meetingStartDate={meetingStartDate}
        />
      </Step>
      {game && game[PRESCRIPTION_FORM_1] && (
        <Step step={STEPS.PRESCRIPTION_FORM_1} current={stepOrders[step]}>
          <PrescriptionForm1
            onChange={setPrescriptionForm}
            onTrigger={setTrigger}
          />
        </Step>
      )}
      {game && game[PRESCRIPTION_FORM_2] && (
        <Step step={STEPS.PRESCRIPTION_FORM_2} current={stepOrders[step]}>
          <PrescriptionForm2
            onChange={setPrescriptionForm}
            onTrigger={setTrigger}
          />
        </Step>
      )}
      {sixIbsParam && (
        <Step step={STEPS.GRADES} current={stepOrders[step]}>
          <Grades data={sixIbsParam} onChange={setGradesState} />
        </Step>
      )}
      <Step step={STEPS.COMMENT} current={stepOrders[step]}>
        <Comment onChange={setComment} />
      </Step>
      <Step step={STEPS.END_CONFIRMATION} current={stepOrders[step]}>
        <div>Вы уверены что хотите завершить встречу?</div>
      </Step>
    </Dialog>
  );
};

export default branch(
  {
    game: currentGameSelector(),
    clients: currentClientsSelector(),
    agents: agentsSelector(),
    currentUser: meSelector(),
    sixIbsParam: sixIbsParamSelector(),
    positions: positionsSelector(),
    institutions: institutionsSelector(),
  },
  MeetingDialog,
);
