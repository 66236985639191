import { createRemote, removeRemote, updateRemote } from '../atomic';
import ENTITIES from '..';
import { execAfterUserConfirmation } from '../../app/actions';

export function createMeeting(store, data) {
  execAfterUserConfirmation(store, () => {
    createRemote(store, ENTITIES.MEETING, data);
  });
}

export function removeMeeting(store, data) {
  execAfterUserConfirmation(store, () => {
    removeRemote(store, ENTITIES.MEETING, data);
  });
}

export function updateMeeting(store, data) {
  updateRemote(store, ENTITIES.MEETING, data);
}
