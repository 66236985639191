import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Table, { TYPES as TABLE_TYPES } from '../../../../components/table';
import { PARAMS } from '../../../../components/form/select';

import { PRESCRIPTION_FORM_STRUCT_2 } from '../../../../store/struct/entities/client';

import styles from '../index.module.scss';

const ID = 'id';
const VISIT = 'visit';
const PATIENTS = 'patients';

const DATA = [
  {
    visit: 'Амбулаторный',
    patients: ['Пациенты с СНсФВ', 'Пациенты с СНсФВ <50%'],
  },
  {
    visit: 'Госпитальный',
    patients: ['Пациенты с СН (независимо от ФВ)', 'Пациенты с ФП и ХСН'],
  },
];

const PrescriptionForm2 = ({ onChange, onTrigger }) => {
  const questionForm = useMemo(() => {
    try {
      return DATA.map((field, fieldIndex) => ({
        [ID]: fieldIndex,
        [VISIT]: field[PRESCRIPTION_FORM_STRUCT_2.VISIT],
        [PATIENTS]: [
          ...field[PRESCRIPTION_FORM_STRUCT_2.PATIENTS].map((title, index) => ({
            [PARAMS.TITLE]: title,
            [PARAMS.ID]: index,
          })),
        ],
      }));
    } catch (e) {
      console.warn(e);
      return [];
    }
  }, []);
  const [state, setState] = useState(
    questionForm.reduce((result, field, index) => {
      result[index] = { visit: field.visit, res: field.patients.map(() => -1) };

      return result;
    }, []),
  );
  const [updateTrigger, setUpdateTrigger] = useState(Date.now());

  const tableConfig = useMemo(
    () => [
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => data[VISIT],
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: data =>
          data[PATIENTS].map((line, index) => (
            <div key={index}>{line.title}</div>
          )),
        getProps: () => ({
          className: styles.patientsTextContainer,
        }),
      },
      {
        type: TABLE_TYPES.INPUT_NUMBER_LIST,
        onChange: (data, values) => {
          values.forEach((val, index) => {
            if (val > -1 && val < 101) {
              const result = state;
              const newState = state[data[ID]]['res'];
              newState[index] = val;
              result[data[ID]]['res'] = newState;
              setState(result);
              setUpdateTrigger(Date.now());
            }
          });
        },
        getValue: data => data[PATIENTS],
        getProps: () => ({
          inputType: 'number',
          placeholder: '0-100',
          min: 0,
          max: 100,
        }),
      },
    ],
    [state],
  );

  useEffect(() => {
    onChange(state);
    onTrigger(Date.now());
  }, [state, onChange, onTrigger, updateTrigger]);

  return (
    <Table
      className={styles.priscriptionTable}
      headers={['Визит', 'Пациенты', 'Договоренность']}
      config={tableConfig}
      data={questionForm}
    />
  );
};

PrescriptionForm2.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default PrescriptionForm2;
