import React from 'react';

import { branch } from 'baobab-react/higher-order';
import find from 'lodash.find';
import includes from 'lodash.includes';
import sumBy from 'lodash.sumby';

import {
  meSelector,
  agentsSelector,
  eventsSelector,
  clientsSelector,
  reportsSelector,
  investsSelector,
  meetingsSelector,
  positionsSelector,
  institutionsSelector,
} from '../../store/struct/selectors';
import Table, { TYPES as TABLE_TYPES } from '../../components/table';
import AGENT_STRUCT from '../../store/struct/entities/agent';
import REPORT_STRUCT from '../../store/struct/entities/report';
import CLIENT_STRUCT, {
  QUESTION_FORM_STRUCT,
} from '../../store/struct/entities/client';
import INSTITUTION_STRUCT from '../../store/struct/entities/institution';
import POSITION_STRUCT from '../../store/struct/entities/position';
import EVENT_STRUCT from '../../store/struct/entities/event';
import INVEST_STRUCT, {
  getCorrespondingInvestId,
} from '../../store/struct/entities/invest';
import USER_STRUCT from '../../store/struct/entities/event';
import { getById } from '../../utils';
import { THEMES } from '../../components/button';
import InfoIcon from '../../components/Icons/info-icon/infoIcon';
import { toDecimalPlaces } from '../../utils';
import { getTime } from '../../utils/time';

import InvestDialog from '../client/investDialog';
import ResultDialog from '../client/resultDialog';

import { roleSelector } from '../../store/struct/app/selectors';
import { ROLES } from '../../store/struct/entities/role';

import commonStyles from '../../styles/common.module.scss';
import styles from './index.module.scss';

const TABLE_HEADERS = [
  'Представитель',
  'Учреждение',
  'Должность',
  'ФИО',
  'Время',
  'Инвестиции',
  'Результаты',
  'Сумма',
  '% от цели',
];

const GRADE = [
  'Не соответствует ожиданиям', // 1, 2, 3
  'Частично соответствует ожиданиям', // 4, 5, 6
  'Полностью соответствует ожиданиям', // 7, 8, 9
  'Превзошёл(-ла) ожидания', // 10, 11, 12
];

const CANCELED_TABLE_HEADERS = [
  'Представитель',
  'Учреждение',
  'Должность',
  'ФИО',
  'Время',
  'Причина отмены',
];

const getArrayWithoutIndexes = indexes => arr =>
  arr.reduce(
    (acc, col, colIndex) => (includes(indexes, colIndex) ? acc : [...acc, col]),
    [],
  );
const getPlannedTime = report => getTime(report[REPORT_STRUCT.PLANNED_START]);
const sortByTime = (rep1, rep2) => {
  if (getPlannedTime(rep1) > getPlannedTime(rep2)) {
    return -1;
  } else if (getPlannedTime(rep1) === getPlannedTime(rep2)) {
    return 0;
  } else {
    return 1;
  }
};

const isAgent = role => role === ROLES.AGENT;

const getPresetsForRole = role =>
  isAgent(role)
    ? {
        msg: 'На данный момент Вами не проведено ни одной встречи',
        filters: [0],
        getProcessedConfig: getArrayWithoutIndexes([0, 6, 7]),
        getFilteredReports: (reports, currentUser, canceled) =>
          reports.filter(
            report =>
              report[REPORT_STRUCT.REPRESENTATIVE_ID] ===
                currentUser[USER_STRUCT.ID] &&
              report[REPORT_STRUCT.STATUS] !== 'canceled',
          ),
      }
    : {
        msg: 'На данный момент в игре не проведено ни одной встречи',
        filters: [0, 1],
        getProcessedConfig: getArrayWithoutIndexes([8]),
        getFilteredReports: (reports, currentUser, canceled) =>
          reports.filter(report =>
            canceled
              ? report[REPORT_STRUCT.STATUS] === 'canceled'
              : report[REPORT_STRUCT.STATUS] !== 'canceled',
          ),
      };

class Reports extends React.Component {
  state = {
    investDialogVisible: false,
    selectedReport: null,
  };

  resultsTableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: report => {
        const agent = getById(
          this.props.agents,
          report[REPORT_STRUCT.REPRESENTATIVE_ID],
        );

        return agent ? agent[AGENT_STRUCT.NAME] : '';
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: report => {
        const client = getById(
          this.props.clients,
          report[REPORT_STRUCT.CLIENT_ID],
        );
        const institution = client
          ? getById(
              this.props.institutions,
              client[CLIENT_STRUCT.INSTITUTION_ID],
            )
          : '';

        return institution ? institution[INSTITUTION_STRUCT.NAME] : '';
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: report => {
        const client = getById(
          this.props.clients,
          report[REPORT_STRUCT.CLIENT_ID],
        );
        const positionId = client ? client[CLIENT_STRUCT.POSITION_ID] : '';
        const position = getById(this.props.positions, positionId);

        return position ? position[POSITION_STRUCT.NAME] : '';
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: report => {
        const client = getById(
          this.props.clients,
          report[REPORT_STRUCT.CLIENT_ID],
        );

        return client ? client[CLIENT_STRUCT.NAME] : '';
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: getPlannedTime,
    },
    [
      {
        type: TABLE_TYPES.TEXT,
        style: {
          display: 'inline-block',
        },
        getValue: report => {
          const events = report[REPORT_STRUCT.APPOINTED_EVENTS];

          return events.reduce((acc, eventId) => {
            const investForEvent = find(this.props.invests, invest => {
              return (
                report[REPORT_STRUCT.CLIENT_ID] ===
                  invest[INVEST_STRUCT.CUSTOMER_ID] &&
                eventId === invest[INVEST_STRUCT.EVENT_ID]
              );
            });

            const event = getById(this.props.events, eventId);

            const cost = investForEvent[INVEST_STRUCT.FINE]
              ? event[EVENT_STRUCT.FINE]
              : event[EVENT_STRUCT.COST];

            return acc + cost;
          }, 0);
        },
      },
      {
        type: TABLE_TYPES.BUTTON,
        onClick: report => {
          this.setState({
            investDialogVisible: true,
            selectedReport: { ...report },
          });
        },
        getProps: () => ({
          className: styles.addBtn,
        }),
        render: InfoIcon,
        theme: THEMES.PRIMARY,
      },
    ],
    {
      type: TABLE_TYPES.TEXT,
      getValue: report => {
        const lines = [];
        const client = getById(
          this.props.clients,
          report[REPORT_STRUCT.CLIENT_ID],
        );
        const form =
          client &&
          client[CLIENT_STRUCT.QUESTION_FORM] &&
          JSON.parse(client[CLIENT_STRUCT.QUESTION_FORM]);
        const grades = report[REPORT_STRUCT.GRADES];
        const comment = report[REPORT_STRUCT.COMMENT];
        const questionFormResultString =
          report[REPORT_STRUCT.QUESTION_FORM_RESULT];
        if (grades) {
          lines.push(
            <div key='grades'>
              <b>Оценки:</b> {grades.join('/')};
            </div>,
          );
        }
        if (comment) {
          lines.push(
            <div key='comment'>
              <b>Комментарий:</b> {comment};
            </div>,
          );
        }
        if (form && questionFormResultString) {
          const questionFormResultArr = JSON.parse(questionFormResultString);
          const questionForm = questionFormResultArr.map((answer, index) => {
            const field = form[index];

            return (
              <span key={index}>
                {field[QUESTION_FORM_STRUCT.QUESTION]}(
                {field[QUESTION_FORM_STRUCT.ANSWERS][answer]});
              </span>
            );
          });
          lines.push(
            <div key='form'>
              <b>Ответы:</b> {questionForm};
            </div>,
          );
        }

        return <div>{lines}</div>;
      },
    },
    [
      {
        type: TABLE_TYPES.TEXT,
        style: {
          display: 'inline-block',
        },
        getValue: report => {
          const grades = report[REPORT_STRUCT.GRADES];
          return toDecimalPlaces(sumBy(grades, grade => grade), 2);
        },
      },
      // {
      //   type: TABLE_TYPES.BUTTON,
      //   onClick: report => {
      //     this.setState({ selectedReport: { ...report } });
      //   },
      //   text: 'Результат',
      //   theme: THEMES.PRIMARY,
      //   min: true,
      // },
    ],
    {
      type: TABLE_TYPES.TEXT,
      getValue: report => {
        if (!report[REPORT_STRUCT.GOAL]) {
          return '-';
        }

        return `${Math.round(
          (100 *
            report[REPORT_STRUCT.GRADES].reduce((acc, item) => acc + item, 0)) /
            report[REPORT_STRUCT.GOAL],
        )}%`;
      },
    },
  ];

  resultsCanceledTableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: report => {
        const agent = getById(
          this.props.agents,
          report[REPORT_STRUCT.REPRESENTATIVE_ID],
        );

        return agent ? agent[AGENT_STRUCT.NAME] : '';
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: report => {
        const client = getById(
          this.props.clients,
          report[REPORT_STRUCT.CLIENT_ID],
        );
        const institution = client
          ? getById(
              this.props.institutions,
              client[CLIENT_STRUCT.INSTITUTION_ID],
            )
          : '';

        return institution ? institution[INSTITUTION_STRUCT.NAME] : '';
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: report => {
        const client = getById(
          this.props.clients,
          report[REPORT_STRUCT.CLIENT_ID],
        );
        const positionId = client ? client[CLIENT_STRUCT.POSITION_ID] : '';
        const position = getById(this.props.positions, positionId);

        return position ? position[POSITION_STRUCT.NAME] : '';
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: report => {
        const client = getById(
          this.props.clients,
          report[REPORT_STRUCT.CLIENT_ID],
        );

        return client ? client[CLIENT_STRUCT.NAME] : '';
      },
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: getPlannedTime,
    },
    {
      type: TABLE_TYPES.TEXT,
      getValue: report => {
        const lines = [];
        const comment = report[REPORT_STRUCT.COMMENT];
        if (comment) {
          lines.push(<div key='comment'>{comment}</div>);
        }

        return <div>{lines}</div>;
      },
    },
  ];

  resultsTableHeaderConfig = [
    {
      props: { className: commonStyles.headerSelect },
    },
    {
      props: { className: commonStyles.headerSelect },
    },
  ];
  resultsTableRowConfig = {
    getKey: rowData =>
      `${rowData[REPORT_STRUCT.REPRESENTATIVE_ID]}_${
        rowData[REPORT_STRUCT.MEETING_RESULTS_ID]
      }`,
  };

  closeDialog = () => {
    this.setState({
      selectedReport: null,
      investDialogVisible: false,
    });
  };

  render() {
    const { currentUser, reports, role, canceled } = this.props;

    if (!currentUser) {
      return '';
    }

    const presets = getPresetsForRole(role);
    const filteredReports = presets.getFilteredReports(
      reports,
      currentUser,
      canceled,
    );
    const sortedReports = filteredReports.sort(sortByTime);
    const { selectedReport, investDialogVisible } = this.state;

    let investIds;
    if (selectedReport) {
      let eventIds = selectedReport[REPORT_STRUCT.APPOINTED_EVENTS];

      investIds = eventIds.map(eventId => {
        const clientId = selectedReport[REPORT_STRUCT.CLIENT_ID];
        return getCorrespondingInvestId(eventId, clientId, this.props.invests);
      });
    }

    return (
      <>
        {sortedReports.length !== 0 ? (
          <Table
            filters={presets.filters}
            keyName={'client_id'}
            data={sortedReports}
            rowConfig={this.resultsTableRowConfig}
            headers={
              canceled
                ? presets.getProcessedConfig(CANCELED_TABLE_HEADERS)
                : presets.getProcessedConfig(TABLE_HEADERS)
            }
            headerConfig={this.resultsTableHeaderConfig}
            config={
              canceled
                ? presets.getProcessedConfig(this.resultsCanceledTableConfig)
                : presets.getProcessedConfig(this.resultsTableConfig)
            }
            className={styles.tableWithHeader}
          />
        ) : (
          presets.msg
        )}
        {!investDialogVisible && selectedReport && (
          <ResultDialog
            onCancel={this.closeDialog}
            selectedReport={selectedReport}
          />
        )}
        {investDialogVisible && selectedReport && (
          <InvestDialog onCancel={this.closeDialog} investIds={investIds} />
        )}
      </>
    );
  }
}

export default branch(
  {
    currentUser: meSelector(),
    agents: agentsSelector(),
    clients: clientsSelector(),
    reports: reportsSelector(),
    institutions: institutionsSelector(),
    positions: positionsSelector(),
    events: eventsSelector(),
    meetings: meetingsSelector(),
    invests: investsSelector(),
    role: roleSelector(),
  },
  Reports,
);
