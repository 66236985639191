import React, { useCallback, useEffect, useRef, useState } from 'react';

import setFormFieldInterface from '../setFieldInterface';

import Select from 'react-select';

export const PARAMS = {
  ID: 'id',
  VALUE: 'value',
  TITLE: 'title',
  DESC: 'desc',
  DISABLED: 'disabled',
  COLOR: 'color',
};

const CustomSelect = ({ options, onChange, placeholder, position }) => {
  const [selectValue, setSelectValue] = useState('');
  let timerRef = useRef();

  const handleChange = useCallback(
    opt => {
      setSelectValue(opt.value);
      onChange(isNaN(opt.value) ? opt.value : Number(opt.value));
    },
    [onChange],
  );

  useEffect(() => {
    return () => {
      clearTimeout(timerRef.current);
    };
  }, []);

  const colourStyles = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      minWidth: '255px',
      cursor: 'pointer',
    }),
    menuList: styles => ({
      ...styles,
      padding: '0',
      margin: 'unset !important',
      borderRadius: '4px',
    }),
    option: (styles, { data, isDisabled }) => {
      return {
        ...styles,
        backgroundColor: data.color ? `rgb(${data.color})` : 'white',
        color: 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',
        ':hover': {
          ...styles[':hover'],
          backgroundColor: `rgba(${data.color},.7)`,
          cursor: 'pointer',
        },
        '::after': {
          ...styles['::after'],
          content: `" |${data.title ? data.title : ''}"`,
        },
      };
    },
    input: styles => ({ ...styles }),
    placeholder: styles => ({ ...styles }),
    singleValue: styles => ({ ...styles }),
  };

  return (
    <>
      <Select
        menuPlacement={position}
        closeMenuOnScroll={true}
        menuPosition='absolute'
        defaultValue={selectValue}
        placeholder={placeholder}
        options={options.map(opt => ({
          id: opt[PARAMS.ID],
          value: opt[PARAMS.VALUE],
          label: opt[PARAMS.TITLE],
          title: opt[PARAMS.DESC],
          color: opt[PARAMS.COLOR],
        }))}
        styles={colourStyles}
        isSearchable={false}
        onChange={handleChange}
      />
    </>
  );
};

export default CustomSelect;

setFormFieldInterface(CustomSelect);
