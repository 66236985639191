const APIs = way => {
  const API_URL = process.env.REACT_APP_API_URL || '';
  const URL =
    process.env.NODE_ENV === 'production' ? API_URL : 'http://localhost:8000/';

  switch (way) {
    case 'comments': {
      return `${URL}comments`;
    }
    case 'delete-game': {
      return `${URL}delete-game`;
    }
    default: {
      return '';
    }
  }
};

export const getComments = (email = '', current_game) => {
  const URL =
    APIs('comments') + '?' + `email=${email}` + `&current_game=${current_game}`;
  return fetch(URL, {
    method: 'GET',
    credentials: 'include',
  })
    .then(response => response.json())
    .catch(error =>
      console.log('Something went wrong! No comments reveived.', error),
    );
};

export const removeGame = game_id => {
  const URL = APIs('delete-game') + '?' + `game_id=${game_id}`;

  const body = new FormData();
  body.append('game_id', game_id);

  return fetch(URL, {
    method: 'POST',
    credentials: 'include',
    body,
  })
    .then(response => {
      if (response.ok) {
        console.log('Game was deleted successfully!');
      }
    })
    .catch(error =>
      console.log('Something went wrong! Game was not removed.', error),
    );
};
