import React, { useCallback, useEffect } from 'react';
import { branch } from 'baobab-react/higher-order';

import {
  pinSendingStatusSelector,
  pinSelector,
  roleSelector,
  authStatusSelector,
  userIdSelector,
} from '../store/struct/app/selectors';
import { logout, sendPin } from '../store/struct/app/actions';
import { ROLES } from '../store/struct/entities/role';
import PinInput from '.././components/pin-input';
import { Loader } from '../components/loader';
import AdminContainer from '../containers/admin';
import AgentContainer from '../containers/agent';
import ClientContainer from '../containers/client';

import Tabs from '../containers/tabs';
import ConfirmDialog from '../containers/confirm-dialog';
import { getSavedCurrentUserId } from '../store/storage';

import scssVars from '../styles/_vars.scss';
import styles from './index.module.scss';

const containers = {
  [ROLES.ADMIN]: AdminContainer,
  [ROLES.AGENT]: AgentContainer,
  [ROLES.CLIENT]: ClientContainer,
};

const App = props => {
  const { role, userId, dispatch, pinSending, authStatus } = props;

  const logOut = useCallback(() => {
    dispatch(logout);
  }, [dispatch]);

  useEffect(() => {
    window.addEventListener('focus', () => {
      const savedUserId = getSavedCurrentUserId();
      if (userId && savedUserId && userId !== savedUserId) {
        logOut();
      }
    });
  }, [logOut]);

  const pinSubmit = useCallback(
    value => {
      dispatch(sendPin, value);
    },
    [dispatch],
  );
  const CurrentContainer = role ? containers[role] : null;

  return (
    <section className={`${scssVars.lightTheme} ${styles.container}`}>
      {!CurrentContainer && authStatus && (
        <>
          <Tabs header='' tabs={[]} />
          <Loader />
        </>
      )}
      {!CurrentContainer && !authStatus && (
        <PinInput disabled={pinSending} onSubmit={pinSubmit} />
      )}
      {CurrentContainer && <CurrentContainer />}
      <ConfirmDialog />
    </section>
  );
};

export default branch(
  {
    pinSending: pinSendingStatusSelector(),
    pin: pinSelector(),
    role: roleSelector(),
    userId: userIdSelector(),
    authStatus: authStatusSelector(),
    entities: 'entities',
  },
  App,
);
