import React from 'react';

import Timer from '../../../../components/timer';

import styles from './index.module.scss';

const Confirmation = ({
  agentName,
  meetingStartDate,
  meetingStartTime,
  meetingStart,
}) => {
  const nowUTCTime = Date.now();

  const startMeeting = Date.parse(meetingStart);

  const timeUntilMeeting = startMeeting - nowUTCTime;
  const sec = Math.trunc(timeUntilMeeting / 1000);

  return (
    <div className={styles.container}>
      <div className={styles.row}>
        Встреча c: <strong>{agentName}</strong>
      </div>
      <div className={styles.row}>
        Начало встречи:{' '}
        <strong>{meetingStartTime + ' ' + meetingStartDate}</strong>
      </div>
      <div className={styles.timer}>
        Начало через:{' '}
        <Timer isHoursNeeded={true} color={'#000'} time={sec > 0 ? sec : 0} />
      </div>
    </div>
  );
};

export default Confirmation;
