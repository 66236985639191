import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Table, { TYPES as TABLE_TYPES } from '../../../../components/table';
import { PARAMS } from '../../../../components/form/select';

import { PRESCRIPTION_FORM_STRUCT } from '../../../../store/struct/entities/client';

import styles from '../index.module.scss';

const ID = 'id';
const TITLE = 'title';
const PROFILES = 'profiles';

const DATA = [
  {
    title: 'Пациенты в неделю по АГ',
    profiles: [
      'АГ+ГЛЖ ( увеличение иММЛЖ)',
      'АГ+метаболический синдром',
      'Другое',
      'Не договорился',
    ],
  },
  {
    title: 'Пациенты в неделю по ХСН',
    profiles: [
      'ХСН с сист дисф + митральная регургитация',
      'ХСН с сист дисф + НРС фибрилляция предсердий',
      'ХСН с сист дисф + НРС желудочковые нарушения ритма',
      'Другое',
      'Не договорился',
    ],
  },
];

const PrescriptionForm = ({ onChange, onTrigger }) => {
  const questionForm = useMemo(() => {
    try {
      return DATA.map((field, fieldIndex) => ({
        [ID]: fieldIndex,
        [TITLE]: field[PRESCRIPTION_FORM_STRUCT.TITLE],
        [PROFILES]: [
          ...field[PRESCRIPTION_FORM_STRUCT.PROFILES].map((profile, index) => ({
            [PARAMS.TITLE]: profile,
            [PARAMS.ID]: index,
          })),
        ],
      }));
    } catch (e) {
      console.warn(e);
      return [];
    }
  }, []);
  const [state, setState] = useState(
    questionForm.reduce((result, field, index) => {
      result[index] = [];

      return result;
    }, []),
  );
  const [profileState, setProfileState] = useState(
    questionForm.reduce((result, field, index) => {
      result[index] = [];

      return result;
    }, []),
  );
  const [countState, setCountState] = useState(
    questionForm.reduce((result, field, index) => {
      result[index] = -1;

      return result;
    }, []),
  );

  useEffect(() => {
    const result = state;
    countState.forEach((value, index) => {
      const newState = state[index].slice();

      newState[0] = value;
      result[index] = newState;
      setState(result);
    });
    profileState.forEach((value, index) => {
      const newState = state[index].slice();

      newState[1] = value;
      result[index] = newState;
      setState(result);
    });
  }, [state, profileState, countState]);

  const tableConfig = useMemo(
    () => [
      {
        type: TABLE_TYPES.TEXT,
        getValue: data => data[TITLE],
      },
      {
        type: TABLE_TYPES.INPUT,
        onChange: (data, value) => {
          if (value > -1 && value < 101) {
            const newState = countState.slice();

            newState[data[ID]] = value;
            setCountState(newState);
          }
        },
        getValue: () => '',
        getProps: () => ({
          inputType: 'number',
          placeholder: '0-100',
          min: 0,
          max: 100,
        }),
      },
      {
        type: TABLE_TYPES.CHECKBOX_LIST,
        onChange: (data, value) => {
          const newState = profileState.slice();
          const newProfileState = newState.slice();

          value.forEach((val, index) => {
            newProfileState[index] = data[PROFILES][val].title;

            newState[data[ID]] = newProfileState;
            setProfileState(newState);
          });
        },
        getValue: () => '',
        getOptions: data => data[PROFILES],
      },
    ],
    [countState, profileState],
  );

  useEffect(() => {
    onChange(state);
    onTrigger(Date.now());
  }, [state, profileState, countState, onChange, onTrigger]);

  return (
    <Table
      className={styles.priscriptionTable}
      headers={['Параметр', 'Количество', 'Профиль']}
      config={tableConfig}
      data={questionForm}
    />
  );
};

PrescriptionForm.propTypes = {
  onChange: PropTypes.func.isRequired,
};

export default PrescriptionForm;
