import React from 'react';
import { branch } from 'baobab-react/higher-order';
import includes from 'lodash.includes';

import {
  institutionsSelector,
  meSelector,
  positionsSelector,
} from '../../store/struct/selectors';
import USER_STRUCT from '../../store/struct/entities/user';
import CLIENT_STRUCT from '../../store/struct/entities/client';
import POSITION_STRUCT from '../../store/struct/entities/position';
import INSTITUTION_STRUCT from '../../store/struct/entities/institution';
import { getById } from '../../utils';

import Tabs from '../tabs';
import Rules from './rules';
import Reports from '../reports';
import Meetings from './meetings';
import AgentsRating from '../agent-rating';
import Queue from './queue';
import { ICONS } from '../../components/Icons';
import TopPanelIcon from '../../components/Icons/top-panel-icon/topPanelIcon';

import styles from '../agent/index.module.scss';

const TABS = {
  RULES: 'rules',
  MEETINGS: 'meetings',
  REPORTS: 'reports',
  CANCELED_MEETINGS: 'canceled_meetings',
  RATINGS: 'rating',
  QUEUES: 'queue',
};

const getTabs = disabledTabs => {
  const getTab = (id, attrs) => ({
    id,
    disabled: includes(disabledTabs, id),
    ...attrs,
  });

  return [
    getTab(TABS.RULES, {
      title: 'Правила',
      content: Rules,
      icon: ICONS.UnionIcon,
    }),
    getTab(TABS.MEETINGS, {
      title: 'Встречи',
      content: Meetings,
      icon: ICONS.MeetIcon,
    }),
    getTab(TABS.REPORTS, {
      title: 'Отчет',
      content: Reports,
      icon: ICONS.MoneyIcon,
    }),
    getTab(TABS.CANCELED_MEETINGS, {
      title: 'Отмененные встречи',
      content: Reports,
      canceled: true,
      icon: ICONS.MoneyIcon,
    }),
    getTab(TABS.RATINGS, {
      title: 'Рейтинг',
      content: AgentsRating,
      icon: ICONS.MarketIcon,
    }),
    getTab(TABS.QUEUES, {
      title: 'Очереди',
      content: Queue,
      icon: ICONS.MarketIcon,
    }),
  ];
};

const ClientContainer = props => {
  const { positions, institutions, me } = props;
  const user = me && me[0];
  const disabledTabs = [];
  if (!(user && user[CLIENT_STRUCT.HAS_ACCESS_TO_REPORTS])) {
    disabledTabs.push(TABS.REPORTS, TABS.RATINGS);
  }
  const tabs = getTabs(disabledTabs);
  const header = user && (
    <TopPanelIcon
      icon={<ICONS.PersonIcon width={14} height={14} />}
      title={'Текущая должность'}
    >
      <span className={styles.position}>
        {
          getById(positions, user[USER_STRUCT.POSITION_ID])[
            POSITION_STRUCT.NAME
          ]
        }
      </span>
      {', '}
      <span className={styles.institution}>
        {
          getById(institutions, user[USER_STRUCT.INSTITUTION_ID])[
            INSTITUTION_STRUCT.NAME
          ]
        }
      </span>
    </TopPanelIcon>
  );

  return (
    <>
      <div className={styles.content}>
        <Tabs tabs={tabs} {...props} header={header} />
      </div>
    </>
  );
};

export default branch(
  {
    me: meSelector(),
    positions: positionsSelector(),
    institutions: institutionsSelector(),
  },
  ClientContainer,
);
