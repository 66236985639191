import React, { useState, useMemo } from 'react';
import { branch } from 'baobab-react/higher-order';
import find from 'lodash.find';
import { ICONS } from '../../components/Icons';
import TopPanelIcon from '../../components/Icons/top-panel-icon/topPanelIcon';

import { gamesSelector } from '../../store/struct/selectors';
import GAME_STRUCT from '../../store/struct/entities/game';

import Tabs from '../tabs';
import Games from './games';
import Users from './users';
import Logs from './logs';

const tabs = [
  {
    title: 'Игры',
    content: Games,
    icon: ICONS.GameIcon,
  },
  {
    title: 'Журнал',
    content: Logs,
    icon: ICONS.UnionIcon,
  },
  {
    title: 'Пользователи',
    content: Users,
    icon: ICONS.PersonIcon,
  },
];

const AdminContainer = props => {
  let { games } = props;

  games = useMemo(() => games.filter(game => !game[GAME_STRUCT.HIDDEN]), [
    games,
  ]);

  const [currentGameId, setCurrentGameId] = useState(null);

  const gamesOptions = useMemo(
    () => games.map(game => ({ id: game.id, title: game.name })),
    [games],
  );
  const currentGame = useMemo(() => find(games, { id: currentGameId }), [
    games,
    currentGameId,
  ]);

  const header = <TopPanelIcon noIcon />;

  return (
    <Tabs
      tabs={tabs}
      {...props}
      games={games}
      header={header}
      gamesOptions={gamesOptions}
      currentGame={currentGame}
      currentGameId={currentGameId}
      setCurrentGameId={setCurrentGameId}
    />
  );
};

export default branch(
  {
    games: gamesSelector(),
  },
  AdminContainer,
);
