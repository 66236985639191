import { monkey } from 'baobab';

import GAME_STRUCT from '../../entities/game';
import TOP_STRUCT from '../../index';
import ENTITY_STRUCT from '../../entities';
import { getById } from '../../../../utils';
import STATUS_STRUCT from '../../entities/status';

const CALCULATED_GAME_STRUCT = {
  ...GAME_STRUCT,
  STATUS: 'status',
};

export const GAMES = 'games';
export const GAME = 'calced_game';
export const WITHOUT_COMMENTS = 'with_comments';
export const PRESCRIPTION_FORM_1 = 'prescription_form_1';
export const PRESCRIPTION_FORM_2 = 'prescription_form_2';
export const IS_TEST = 'test';
export const GAME_RULES = 'game_rules';

export default CALCULATED_GAME_STRUCT;

const withoutCommentsFlag = 'no_comments_in_form';
const is_test = 'test';
const prescriptionForm1 = 'prescription_form_1';
const prescriptionForm2 = 'prescription_form_2';

export const getInitialState = () => ({
  [GAME]: monkey(['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.GAME], game => {
    if (Array.isArray(game)) {
      return game;
    } else {
      const rules = game[GAME_STRUCT.RULES];
      const flags = game[GAME_STRUCT.FLAGS];

      return {
        ...game,
        [WITHOUT_COMMENTS]: flags && flags.includes(withoutCommentsFlag),
        [PRESCRIPTION_FORM_1]: flags && flags.includes(prescriptionForm1),
        [PRESCRIPTION_FORM_2]: flags && flags.includes(prescriptionForm2),
      };
    }
  }),
  [GAMES]: monkey(
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.GAME],
    ['..', TOP_STRUCT.ENTITIES, ENTITY_STRUCT.STATUS],
    (games, status) => {
      if (!Array.isArray(games)) {
        return [];
      }

      return games.map(game => ({
        ...game,
        status: getById(status, game[GAME_STRUCT.STATUS_ID])[
          STATUS_STRUCT.TITLE
        ],
        [IS_TEST]:
          game[GAME_STRUCT.FLAGS] && game[GAME_STRUCT.FLAGS].includes(is_test),
      }));
    },
  ),
});
