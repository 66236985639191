import React from 'react';

import styles from './index.module.scss';

const Confirmation = ({ agentName, meetingStartDate, meetingStartTime }) => {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        Встреча c: <strong>{agentName}</strong>
      </div>
      <div className={styles.row}>
        Начало встречи:{' '}
        <strong>{meetingStartTime + ' ' + meetingStartDate}</strong>
      </div>
    </div>
  );
};

export default Confirmation;
