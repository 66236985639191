import ENTITIES from '..';
import { updateRemote } from '../atomic';
import GAME_STRUCT, { GAME_STATUS } from '.';
import { connection } from '../../..';
import { removeGame } from '../../queries/queries';

export function startGame(store, game) {
  updateRemote(store, ENTITIES.GAME, {
    [GAME_STRUCT.ID]: game[GAME_STRUCT.ID],
    [GAME_STRUCT.EXERCISE_ID]: game[GAME_STRUCT.EXERCISE_ID],
    [GAME_STRUCT.STATUS_ID]: GAME_STATUS.PLAYING,
  });
}

export function finishGame(store, game) {
  updateRemote(store, ENTITIES.GAME, {
    [GAME_STRUCT.ID]: game[GAME_STRUCT.ID],
    [GAME_STRUCT.EXERCISE_ID]: game[GAME_STRUCT.EXERCISE_ID],
    [GAME_STRUCT.STATUS_ID]: GAME_STATUS.FINISHED,
  });
}

export function editGame(store, game) {
  updateRemote(store, ENTITIES.GAME, game);
}

export function deleteGame(store, game) {
  updateRemote(store, ENTITIES.GAME, {
    [GAME_STRUCT.ID]: game[GAME_STRUCT.ID],
    [GAME_STRUCT.EXERCISE_ID]: game[GAME_STRUCT.EXERCISE_ID],
    [GAME_STRUCT.HIDDEN]: 1,
  });

  // real removing from db
  removeGame(game[GAME_STRUCT.ID]);
}

export function exportFile1(store, data) {
  connection.exportFile1(data);
}

export function exportFile2(store, data) {
  connection.exportFile2(data);
}

export function uploadFile(store, file) {
  connection.uploadFile(file);
}

export function sendGamePins(store, game) {
  const siteUrl = document.location.href;
  connection.sendGamePins(game[GAME_STRUCT.ID], siteUrl);
}
