import React, { useCallback, useState, useEffect } from 'react';
import { branch } from 'baobab-react/higher-order';
import PropTypes from 'prop-types';
import forOwn from 'lodash.forown';
import find from 'lodash.find';

import {
  agentsSelector,
  clientMeetingsSelector,
  eventsSelector,
  investsSelector,
  meetingsSelector,
  meSelector,
  reportsSelector,
  teamsSelector,
} from '../../../store/struct/selectors';
import MEETING_STRUCT, {
  MEETING_STATUSES,
} from '../../../store/struct/entities/meeting';
import RESULT_STRUCT from '../../../store/struct/entities/meetingResult';
import AGENT_STRUCT from '../../../store/struct/entities/agent';
import INVEST_STRUCT, {
  getClientEventsIds,
  getClientInvestIds,
  getResultCoef,
} from '../../../store/struct/entities/invest';
import EVENT_STRUCT from '../../../store/struct/entities/event';
import REPORT_STRUCT from '../../../store/struct/entities/report';

import Table, { TYPES as TABLE_TYPES } from '../../../components/table';
import MeetingDialog from './meetingDialog';
import { updateMeeting } from '../../../store/struct/entities/meeting/actions';
import { sendMeetingResult } from '../../../store/struct/entities/meetingResult/actions';
import { getById } from '../../../utils';

import InvestDialog from '../investDialog';
import ResultDialog from '../resultDialog';
import { THEMES } from '../../../components/button';
import InfoIcon from '../../../components/Icons/info-icon/infoIcon';
import StatusText from '../../../components/status-text/status-text';

import styles from './index.module.scss';
import CALCULATED_MEETING_STRUCT from '../../../store/struct/calculated/meeting';
import { isDatesEqual } from '../../../utils/time';
import TIMESLOT_STRUCT from '../../../store/struct/entities/timeslots';

const HEADERS = ['Представитель', 'Инвестиции (y.e)', 'Статус', 'Действие'];

const Meetings = ({
  agents,
  events,
  invests,
  meetings,
  dispatch,
  reports,
  currentUser,
  clientMeetings,
}) => {
  const currentClient = currentUser[0];
  // we have timeslots in the meetings state for now

  const [currentMeeting, setCurrentMeeting] = useState(null);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [investDialogVisible, setInvestDialogVisible] = useState(false);
  const [resultDialogVisible, setResultDialogVisible] = useState(false);
  const [meetingStarted, setMeetingStarted] = useState(true);
  const [endedMeeting, setEndedMeeting] = useState(null);

  if (currentMeeting && !selectedMeeting) {
    setMeetingStarted(true);
    setSelectedMeeting(currentMeeting);
  } else if (selectedMeeting && !currentMeeting) {
    setMeetingStarted(false);
    setSelectedMeeting(null);
  }

  useEffect(() => {
    const currMeeting = find(meetings, {
      [MEETING_STRUCT.STATUS]: MEETING_STATUSES.inProgress,
      [MEETING_STRUCT.CLIENT_END]: null,
    });
    if (currMeeting && !currMeeting[MEETING_STRUCT.CLIENT_END]) {
      setCurrentMeeting(currMeeting);
      setSelectedMeeting(currMeeting);
    }
  }, [meetings]);

  const onSetCurrentMeeting = meetingData => {
    setCurrentMeeting(meetingData);
  };
  const onMeetingStart = useCallback(() => {
    // Do not dispatch an event if the meeting starts in the second time.
    if (
      selectedMeeting[MEETING_STRUCT.STATUS] !== MEETING_STATUSES.inProgress
    ) {
      dispatch(updateMeeting, {
        id: selectedMeeting[MEETING_STRUCT.MEETING_ID],
        timeslot_id: selectedMeeting[MEETING_STRUCT.ID],
        // When meeting was started, agent can't remove this meeting
        started: true, // send flag of starting
      });
    }
  }, [dispatch, selectedMeeting]);
  const onMeetingEnd = useCallback(
    ({
      prescriptionFormResult,
      gradesState,
      questionFormResult,
      comment,
      status,
    }) => {
      const coef = getResultCoef(
        getClientEventsIds(selectedMeeting, invests),
        events,
        invests,
        currentClient,
      );
      const gradesWithCoef = {};
      forOwn(gradesState, (val, key) => {
        gradesWithCoef[key] = gradesState[key] * coef;
      });
      dispatch(sendMeetingResult, {
        [RESULT_STRUCT.MEETING]: {
          id: selectedMeeting[MEETING_STRUCT.MEETING_ID],
        },
        [RESULT_STRUCT.PRESCRIPTION]: JSON.stringify(prescriptionFormResult),
        [RESULT_STRUCT.GRADES]: gradesWithCoef,
        [RESULT_STRUCT.COMMENT]: comment,
        [RESULT_STRUCT.QUESTION_FORM_RESULT]: JSON.stringify(
          questionFormResult,
        ),
        [RESULT_STRUCT.STATUS]: status,
      });
      setMeetingStarted(false);
      setCurrentMeeting(null);
      setEndedMeeting(selectedMeeting);
    },
    [selectedMeeting, invests, events, currentClient, dispatch],
  );

  const meetingsTableConfig = [
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => {
        const userId = data[MEETING_STRUCT.REPRESENTATIVE_ID];
        const agent = getById(agents, userId);

        return agent && agent[AGENT_STRUCT.NAME];
      },
    },
    [
      {
        type: TABLE_TYPES.TEXT,
        style: {
          display: 'inline-block',
        },
        getValue: meetingData => {
          return getClientInvestIds(meetingData, invests).reduce(
            (acc, investId) => {
              const invest = getById(invests, investId);
              const event = getById(events, invest[INVEST_STRUCT.EVENT_ID]);

              const cost = invest[INVEST_STRUCT.FINE]
                ? event[EVENT_STRUCT.FINE]
                : event[EVENT_STRUCT.COST];

              return acc + cost;
            },
            0,
          );
        },
      },
      {
        type: TABLE_TYPES.BUTTON,
        onClick: meetingData => {
          setSelectedMeeting(meetingData);
          setInvestDialogVisible(true);
        },
        theme: THEMES.PRIMARY,
        getProps: () => ({
          className: styles.addBtn,
        }),
        render: InfoIcon,
      },
    ],
    {
      type: TABLE_TYPES.TEXT,
      getValue: data => {
        const time = data[CALCULATED_MEETING_STRUCT.TIME];
        const date = data[CALCULATED_MEETING_STRUCT.DATE];
        let status = data[MEETING_STRUCT.STATUS];
        if (
          (endedMeeting &&
            endedMeeting[MEETING_STRUCT.MEETING_ID] ===
              data[MEETING_STRUCT.MEETING_ID]) ||
          (data && data[CALCULATED_MEETING_STRUCT.REP_ACTUAL_END])
        ) {
          status = 'done';
        }
        return <StatusText text={`${date} ${time}`} status={status} />;
      },
    },
    {
      type: TABLE_TYPES.CONDITIONAL,
      getComponentIndex: currentMeeting => {
        const today = new Date();
        const meetingDate = currentMeeting[TIMESLOT_STRUCT.START];
        if (!isDatesEqual(today, meetingDate)) {
          return 0;
        }
        if (
          endedMeeting &&
          endedMeeting[MEETING_STRUCT.MEETING_ID] ===
            currentMeeting[MEETING_STRUCT.MEETING_ID]
        ) {
          return 2;
        }
        const isEndMeeting =
          currentMeeting[CALCULATED_MEETING_STRUCT.REP_ACTUAL_END];
        if (isEndMeeting) {
          return 2;
        }
        switch (currentMeeting[MEETING_STRUCT.STATUS]) {
          case MEETING_STATUSES.pending:
          case MEETING_STATUSES.inProgress:
            return 1;
          case MEETING_STATUSES.done:
            return 2;
          case MEETING_STATUSES.canceled:
            return 3;
          default:
            return;
        }
      },
      components: [
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => 'Не сегодня',
        },
        {
          type: TABLE_TYPES.BUTTON,
          onClick: onSetCurrentMeeting,
          text: 'Начать',
          theme: THEMES.PRIMARY,
          min: true,
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => 'Завершена',
        },
        {
          type: TABLE_TYPES.TEXT,
          getValue: () => 'Отменена',
        },
      ],
    },
  ];

  const onCloseDialog = () => {
    setInvestDialogVisible(false);
    setResultDialogVisible(false);
    setSelectedMeeting(null);
  };

  let selectedReport;
  if (selectedMeeting) {
    selectedReport = find(
      reports,
      report =>
        report[REPORT_STRUCT.MEETING_ID] ===
        selectedMeeting[MEETING_STRUCT.MEETING_ID],
    );
  }

  return (
    <>
      {clientMeetings.length ? (
        <Table
          className={styles.tableFullHeight}
          headers={HEADERS}
          data={clientMeetings}
          config={meetingsTableConfig}
        />
      ) : (
        'На данный момент с Вами не запланировано ни одной встречи'
      )}

      {selectedMeeting && meetingStarted && (
        <MeetingDialog
          onEnd={onMeetingEnd}
          agentId={selectedMeeting[MEETING_STRUCT.REPRESENTATIVE_ID]}
          comments={selectedMeeting[MEETING_STRUCT.COMMENTS]}
          meetingStartTime={selectedMeeting[CALCULATED_MEETING_STRUCT.TIME]}
          meetingStartDate={selectedMeeting[CALCULATED_MEETING_STRUCT.DATE]}
          meetingStart={selectedMeeting[TIMESLOT_STRUCT.START]}
          onStart={onMeetingStart}
          onCancelStart={() => setCurrentMeeting(null)}
          actualStart={selectedMeeting[MEETING_STRUCT.ACTUAL_START]}
          meetingStatus={selectedMeeting[MEETING_STRUCT.STATUS]}
        />
      )}

      {selectedMeeting && investDialogVisible && (
        <InvestDialog
          onCancel={onCloseDialog}
          investIds={getClientInvestIds(selectedMeeting, invests)}
        />
      )}
      {resultDialogVisible && (
        <ResultDialog
          onCancel={onCloseDialog}
          selectedReport={selectedReport}
        />
      )}
    </>
  );
};

Meetings.propTypes = {
  currentUser: PropTypes.array.isRequired,
};
Meetings.defaultProps = {
  currentUser: [],
};

export default branch(
  {
    currentUser: meSelector(),
    agents: agentsSelector(),
    teams: teamsSelector(),
    events: eventsSelector(),
    invests: investsSelector(),
    meetings: meetingsSelector(),
    reports: reportsSelector(),
    clientMeetings: clientMeetingsSelector(),
  },
  Meetings,
);
