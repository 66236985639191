import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import requiredIf from 'react-required-if';
import styles from './topPanelIcon.module.scss';

import { logout } from '../../../store/struct/app/actions';
import { branch } from 'baobab-react/dist-modules/higher-order';

const TopPanelIcon = ({ noIcon = false, icon, title, children, dispatch }) => {
  const logOut = useCallback(() => {
    dispatch(logout);
  }, [dispatch]);

  return (
    <div className={styles.root}>
      {!noIcon ? (
        <div className={styles.topPanelIcon}>
          <div className={styles.icon}>{icon}</div>
          <div>
            <div className={styles.title}>{title}</div>
            <div className={styles.value}>{children}</div>
          </div>
        </div>
      ) : (
        <div />
      )}

      <button onClick={logOut} className={styles.button__exit}>
        Выход
      </button>
    </div>
  );
};

TopPanelIcon.propTypes = {
  icon: requiredIf(PropTypes.any, props => !props.noIcon),
  title: requiredIf(PropTypes.string, props => !props.noIcon),
};

export default branch({}, TopPanelIcon);
