import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Button from '../button';

import styles from './index.module.scss';

const digits = '0123456789';
const alphabet = 'abcdefghijklmnopqrstuvwxyz';
const lowerCaseLettersWithoutL = alphabet.replace('l', '');
const pinSymbols = [
  digits,
  alphabet.replace('i', '').toUpperCase(),
  lowerCaseLettersWithoutL,
  digits,
  lowerCaseLettersWithoutL,
];

const PIN_LENGTH = pinSymbols.length;
const matchCompleteMask = str =>
  pinSymbols.every((symbols, index) => symbols.indexOf(str[index]) > -1);
const matchInputMask = str =>
  str
    .split('')
    .every((letter, index) => pinSymbols[index].indexOf(letter) > -1) ||
  matchCompleteMask(str);
const trim = str => (matchInputMask(str) ? str : trim(str.slice(0, -1)));

const PinInput = props => {
  const { disabled, onSubmit } = props;
  const [currentPin, setPin] = useState('');

  const onChange = useCallback(event => {
    setPin(trim(event.target.value));
  }, []);

  const handleSubmit = useCallback(
    e => {
      e.preventDefault();
      onSubmit(currentPin);
    },
    [onSubmit, currentPin],
  );

  const handleKeyDown = useCallback(
    e => {
      if (e.key === 'Enter' && currentPin.length === PIN_LENGTH) {
        onSubmit(currentPin);
      }
    },
    [onSubmit, currentPin],
  );

  return (
    <div className={styles.container} onKeyDown={handleKeyDown}>
      <section className={styles.innerWrapper}>
        <h1>Добро пожаловать!</h1>
        <p>Введите свой PIN-код</p>
        <form className={styles.form}>
          <div className={styles.inputsWrapper}>
            <input
              type='text'
              onChange={onChange}
              maxLength={PIN_LENGTH}
              className={styles.input}
              value={currentPin}
              autoComplete='off'
            />
          </div>

          <Button
            className={styles.submit}
            onClick={handleSubmit}
            disabled={disabled || !matchCompleteMask(currentPin)}
          >
            Войти
          </Button>
        </form>
      </section>
    </div>
  );
};

export default PinInput;

PinInput.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};
PinInput.defaultProps = {
  disabled: false,
};
