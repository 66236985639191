import React, { useCallback, useMemo } from 'react';
import includes from 'lodash.includes';

import setFormFieldInterface from '../setFieldInterface';

import styles from './index.module.scss';

export const PARAMS = {
  ID: 'id',
  TITLE: 'title',
  THEME: 'theme',
  DISABLED: 'disabled',
  CLASSNAME: 'className',
};

const Checkboxes = props => {
  const { value, onChange, options } = props;
  const handleChange = useCallback(
    id => {
      onChange(
        options.reduce((acc, opt) => {
          if (
            (includes(value, opt[PARAMS.ID]) && id !== opt[PARAMS.ID]) ||
            (!includes(value, opt[PARAMS.ID]) && id === opt[PARAMS.ID])
          ) {
            acc.push(opt[PARAMS.ID]);
          }

          return acc;
        }, []),
      );
    },
    [value, options, onChange],
  );

  const disabled = useMemo(() => {
    if (value && value.length > 1) {
      return (
        value && options.map(opt => opt.id).filter(v => value.indexOf(v) === -1)
      );
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <div className={`${styles.container}`}>
      {options.map(opt => {
        return (
          <label
            key={opt[PARAMS.ID]}
            className={disabled.indexOf(opt.id) !== -1 ? styles.disabled : null}
          >
            <input
              type='checkbox'
              className={`${styles.checkbox} ${opt[PARAMS.CLASSNAME]}`}
              onChange={() => handleChange(opt[PARAMS.ID])}
              checked={includes(value, opt[PARAMS.ID])}
              disabled={disabled.indexOf(opt.id) !== -1 ? true : false}
            />
            <span className={styles.box} />
            {opt[PARAMS.TITLE]}
          </label>
        );
      })}
    </div>
  );
};

export default Checkboxes;

setFormFieldInterface(Checkboxes);
