import { monkey } from 'baobab';

import STRUCT from '../..';
import ENTITIES from '../../entities';
import GAME_STRUCT from '../../entities/game';
import MEETING_STRUCT from '../../entities/meeting';
import MEETING_RESULT_STRUCT from '../../entities/meetingResult';
import INVEST_STRUCT from '../../entities/invest'; //, { getClientEventsIds, getResultCoef }
import EVENT_STRUCT from '../../entities/event';
import { ROLES } from '../../entities/role';
import TIMESLOT_STRUCT from '../../entities/timeslots';
import USER_STRUCT from '../../entities/user';
import CLIENT_STRUCT, { QUESTION_FORM_STRUCT } from '../../entities/client';
import LOG_STRUCT from '../../entities/log';
import { getById } from '../../../../utils';
import { getDate, getTime } from '../../../../utils/time';

const CALCULATED_LOG_STRUCT = {
  ID: LOG_STRUCT.ID,
  GAME_ID: LOG_STRUCT.GAME_ID,
  DATETIME: LOG_STRUCT.DATETIME,
  ROLE: LOG_STRUCT.ROLE,
  USER: 'user',
  ACTION: 'action',
  ENTITY: 'entity',
  MEETING_ID: 'entityId',
  DATA: 'data',
};

export const LOGS = 'log';

const ACTIONS = {
  INIT: 'Подключение',
  CREATE: 'Создание',
  UPDATE: 'Изменение',
  DELETE: 'Удаление',
};
const ENTITY_TRANSCRIPTIONS = {
  [ENTITIES.AGENT]: 'Представитель',
  [ENTITIES.CLIENT]: 'Клиент',
  [ENTITIES.INVEST]: 'Инвестиция',
  [ENTITIES.GAME]: 'Игра',
  [ENTITIES.MEETING]: 'Встреча',
  [ENTITIES.REP_MEETING_RESULT]: 'Результат встречи',
  [ENTITIES.MEETING_RESULT]: 'Результат встречи',
  [ENTITIES.TIMESLOTS]: 'Таймслот клиента',
};
const PARAMS_TRANSCRIPTIONS = {
  [MEETING_RESULT_STRUCT.QUESTION_FORM_RESULT]: 'Ответы',
  [MEETING_RESULT_STRUCT.COMMENT]: 'Комментарий',
  [ROLES.ADMIN]: 'Администратор',
  [ROLES.CLIENT]: 'Клиент',
  [ROLES.AGENT]: 'Представитель',
};

const getTranscription = paramName =>
  PARAMS_TRANSCRIPTIONS[paramName] || paramName;

const getUserName = role => (id, users) => {
  const userName = getById(users, id)[USER_STRUCT.NAME];

  return [PARAMS_TRANSCRIPTIONS[role], userName];
};
const getClientName = getUserName(ROLES.CLIENT);
const getAgentName = getUserName(ROLES.AGENT);
const getTimeslotDetails = (timeslotId, role, timeslots, clients) => {
  const timeslot = getById(timeslots, timeslotId);
  if (!timeslot) {
    return [];
  }
  const details = [];
  const clientName = getClientName(
    timeslot[TIMESLOT_STRUCT.CUSTOMER_ID],
    clients,
  );

  if (clientName && role !== ROLES.CLIENT) {
    details.push(clientName);
  }
  details.push(['Дата', getDate(timeslot[TIMESLOT_STRUCT.START])]);
  details.push(['Начало', getTime(timeslot[TIMESLOT_STRUCT.START])]);
  /*details.push([
    'Конец',
    getUTCDate(timeslot[TIMESLOT_STRUCT.END]).toLocaleTimeString(),
  ]);*/

  return details;
};

const prescriptionForm2 = 'prescription_form_2';

export const getInitialState = () => ({
  [LOGS]: monkey(
    [STRUCT.ENTITIES, ENTITIES.GAME],
    [STRUCT.ENTITIES, ENTITIES.LOG],
    [STRUCT.ENTITIES, ENTITIES.AGENT],
    [STRUCT.ENTITIES, ENTITIES.CLIENT],
    [STRUCT.ENTITIES, ENTITIES.MEETING],
    [STRUCT.ENTITIES, ENTITIES.EVENT],
    [STRUCT.ENTITIES, ENTITIES.INVEST],
    [STRUCT.ENTITIES, ENTITIES.REPORTS],
    [STRUCT.ENTITIES, ENTITIES.TIMESLOTS],
    function(
      games,
      logs,
      agents,
      clients,
      meetings,
      events,
      invests,
      reports,
      timeslots,
    ) {
      return logs
        .filter(log => {
          try {
            const data = JSON.parse(log[LOG_STRUCT.DATA]);

            return data[0] !== 'INIT' && log[LOG_STRUCT.ROLE] !== ROLES.ADMIN;
          } catch {
            console.log(log[LOG_STRUCT.DATA]);

            return false;
          }
        })
        .map(log => {
          const currentGame = getById(games, log[LOG_STRUCT.GAME_ID]);
          const flags = currentGame && currentGame[GAME_STRUCT.FLAGS];

          const data = JSON.parse(log[LOG_STRUCT.DATA]);
          const role = log[LOG_STRUCT.ROLE];
          const currentUser = getById(
            [agents, clients][role - 2],
            log[LOG_STRUCT.USER_ID],
          );
          const details = [];
          let meetingId = '';

          if (data[1] === 'meeting' && role !== ROLES.AGENT) {
            const meeting = getById(meetings, data[2][MEETING_STRUCT.ID]);

            if (meeting) {
              const agentName = getAgentName(
                meeting[MEETING_STRUCT.REPRESENTATIVE_ID],
                agents,
              );

              details.push(agentName);
            } else {
              console.log(
                data,
                data[2][MEETING_STRUCT.ID],
                getById(meetings, data[2][MEETING_STRUCT.ID]),
              );
            }
          }
          if (data[2]) {
            const timeslot = getById(
              timeslots,
              data[2][MEETING_STRUCT.TIMESLOT_ID],
            );
            meetingId = timeslot && timeslot.meeting_id;
            for (let prop in data[2]) {
              if (!data[2].hasOwnProperty(prop)) {
                continue;
              }
              switch (prop) {
                case MEETING_STRUCT.ID:
                case USER_STRUCT.TEAM_ID:
                case TIMESLOT_STRUCT.GAME_EXERCISE_ID:
                  break;
                case MEETING_STRUCT.REPRESENTATIVE_ID:
                  if (role === ROLES.AGENT) {
                    continue;
                  }
                  const agentName = getAgentName(agents, data[2][prop]);
                  if (agentName) {
                    details.push(agentName);
                  }
                  break;
                case MEETING_STRUCT.CUSTOMER_ID:
                  const clientName = getClientName(data[2][prop], clients);

                  if (clientName && role !== ROLES.CLIENT) {
                    details.push(clientName);
                  }
                  break;
                case INVEST_STRUCT.EVENT_ID:
                  const event = getById(events, data[2][prop]);

                  if (!event) {
                    continue;
                  }
                  details.push(['Наименование', event[EVENT_STRUCT.TITLE]]);
                  details.push(['Стоимость', event[EVENT_STRUCT.COST]]);
                  details.push(['Эффект', event[EVENT_STRUCT.EFFECT]]);
                  details.push(['Штраф', event[EVENT_STRUCT.FINE]]);
                  break;
                case MEETING_STRUCT.TIMESLOT_ID:
                  details.push(
                    ...getTimeslotDetails(
                      data[2][prop],
                      role,
                      timeslots,
                      clients,
                    ),
                  );
                  break;
                case MEETING_STRUCT.STARTED:
                  details.push(['Начата', data[2][prop] ? 'Да' : 'Нет']);
                  break;
                case MEETING_STRUCT.STARTED_REP:
                  details.push(['Начата', data[2][prop] ? 'Да' : 'Нет']);
                  break;
                case MEETING_RESULT_STRUCT.MEETING:
                  const meeting = getById(meetings, data[2][prop].id);
                  if (!meeting) {
                    continue;
                  }
                  const agent = getAgentName(
                    meeting[MEETING_STRUCT.REPRESENTATIVE_ID],
                    agents,
                  );
                  if (agent) {
                    details.push(agent);
                  }
                  /*const eventsIds = getClientEventsIds(meeting, invests); console.log(eventsIds);
                const coef = getResultCoef(eventsIds, events, invests, { id: log[LOG_STRUCT.USER_ID] });
                details.push(
                  ...getTimeslotDetails(
                    meeting[MEETING_STRUCT.TIMESLOT_ID],
                    role,
                    timeslots,
                    clients,
                  ),
                );
                details.push([
                  'Результирующий коэффициент',
                  coef,
                ]);*/
                  break;
                case MEETING_RESULT_STRUCT.QUESTION_FORM_RESULT:
                  const resultsString = data[2][prop];
                  const questionsString =
                    currentUser[CLIENT_STRUCT.QUESTION_FORM];

                  if (questionsString && resultsString) {
                    const questions = JSON.parse(questionsString);
                    const results = JSON.parse(resultsString);

                    details.push([
                      'Ответы',
                      results
                        .map((answerIndex, index) => {
                          const field = questions[index];
                          const question = field[QUESTION_FORM_STRUCT.QUESTION];
                          const answer =
                            field[QUESTION_FORM_STRUCT.ANSWERS][answerIndex];

                          return `${question} - ${answer}`;
                        })
                        .join('; '),
                    ]);
                  }
                  break;
                case MEETING_RESULT_STRUCT.GRADES:
                  const grades = data[2][prop];
                  const gradesArr = [];
                  for (let grade in grades) {
                    if (grades.hasOwnProperty(grade)) {
                      gradesArr.push(
                        `${grade}: ${grades[grade] &&
                          grades[grade].toFixed(2)}`,
                      );
                    }
                  }
                  details.push(['Оценки', gradesArr.join('; ')]);
                  break;
                case MEETING_RESULT_STRUCT.PRESCRIPTION:
                  const prescriptionForm2Flag = flags
                    ? flags.includes(prescriptionForm2)
                    : false;

                  if (prescriptionForm2Flag) {
                    const prescriptionResults = data[2][prop];
                    const prescriptionFormResultArr = JSON.parse(
                      prescriptionResults,
                    );
                    const DATA = [
                      {
                        visit: 'Амбулаторный',
                        patients: ['Пациенты с СНсФВ', 'Пациенты с СНсФВ <50%'],
                      },
                      {
                        visit: 'Госпитальный',
                        patients: [
                          'Пациенты с СН (независимо от ФВ)',
                          'Пациенты с ФП и ХСН',
                        ],
                      },
                    ];

                    let result = '';

                    prescriptionFormResultArr.forEach(answerObj => {
                      let answerResults = '';
                      DATA.find(
                        val => val.visit === answerObj.visit,
                      ).patients.forEach((patient, index) => {
                        answerResults =
                          answerResults +
                          `${patient} - ${answerObj.res[index]} `;
                      });

                      result =
                        result + `${answerObj.visit}: ${answerResults}; `;
                    });

                    details.push(['Назначения', result]);
                  } else {
                    let isResult = true;
                    const prescriptionResults = data[2][prop];
                    const prescriptionFormResultArr = JSON.parse(
                      prescriptionResults,
                    );

                    const title = [
                      'Количество пациентов по АГ',
                      'Профиль пациентов по АГ',
                      'Количество пациентов по ХСН',
                      'Профиль пациентов по ХСН',
                    ];
                    let currIndex = 0;
                    const result = [];

                    prescriptionFormResultArr.map(answerArr => {
                      answerArr.forEach(value => {
                        if (value === -1) {
                          isResult = false;
                        }
                        const question = title[currIndex];
                        let res = '';
                        if (value !== -1) {
                          typeof value === 'string'
                            ? (res = value)
                            : (res = value.join(', '));
                        }

                        const answer = res.replace(/,\s*$/, '');

                        currIndex++;
                        result.push(`${question} - ${answer}`);
                      });
                    });
                    isResult && details.push(['Назначения', result.join('; ')]);
                  }
                  break;
                case MEETING_RESULT_STRUCT.STATUS:
                  const status = data[2][prop];
                  const comment = data[2][MEETING_RESULT_STRUCT.COMMENT];
                  if (status === 'canceled') {
                    details.length = 1;
                    details.push(['Встреча отменена', 'Да']);
                    details.push([
                      getTranscription(MEETING_RESULT_STRUCT.COMMENT),
                      comment,
                    ]);
                  }

                  break;
                default:
                  details.push([
                    getTranscription(prop),
                    JSON.stringify(data[2][prop]),
                  ]);
              }
            }
          }

          return {
            [CALCULATED_LOG_STRUCT.ID]: log[LOG_STRUCT.ID],
            [CALCULATED_LOG_STRUCT.GAME_ID]: log[LOG_STRUCT.GAME_ID],
            [CALCULATED_LOG_STRUCT.DATETIME]: log[LOG_STRUCT.DATETIME],
            [CALCULATED_LOG_STRUCT.ROLE]: PARAMS_TRANSCRIPTIONS[role],
            [CALCULATED_LOG_STRUCT.USER]:
              role > 1 ? currentUser[USER_STRUCT.NAME] : '',
            [CALCULATED_LOG_STRUCT.ACTION]: ACTIONS[data[0]],
            [CALCULATED_LOG_STRUCT.ENTITY]:
              data[0] === 'INIT'
                ? 'Все подписки' //data[1].join('  ')
                : ENTITY_TRANSCRIPTIONS[data[1]] || data[1],
            [CALCULATED_LOG_STRUCT.MEETING_ID]: data[2][
              MEETING_RESULT_STRUCT.MEETING
            ]
              ? data[2][MEETING_RESULT_STRUCT.MEETING][MEETING_STRUCT.ID]
              : meetingId
              ? meetingId
              : '',
            [CALCULATED_LOG_STRUCT.DATA]: details || [],
          };
        });
    },
  ),
});

export default CALCULATED_LOG_STRUCT;
