import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { branch } from 'baobab-react/higher-order';
import find from 'lodash.find';
import forOwn from 'lodash.forown';

import {
  createMeeting,
  removeMeeting,
  updateMeeting,
} from '../../../store/struct/entities/meeting/actions';
import {
  currentClientsSelector,
  currentGameSelector,
  agentMeetingsSelector,
  meSelector,
  timeslotsSelector,
} from '../../../store/struct/selectors';
import RESULT_STRUCT from '../../../store/struct/entities/meetingResult';
import POSITION_STRUCT from '../../../store/struct/entities/position';
import INSTITUTION_STRUCT from '../../../store/struct/entities/institution';
import TIMESLOT_STRUCT from '../../../store/struct/entities/timeslots';
import CLIENT_STRUCT from '../../../store/struct/entities/client';
import GAME_STRUCT from '../../../store/struct/entities/game';
import MEETING_STRUCT, {
  MEETING_STATUSES,
} from '../../../store/struct/entities/meeting';
import CALCULATED_MEETING_STRUCT from '../../../store/struct/calculated/meeting';

import { sendRepMeetingResult } from '../../../store/struct/entities/meetingResult/actions';

import Table, { TYPES as TABLE_TYPES } from '../../../components/table';
import Form, {
  PARAMS,
  TYPES as FORM_TYPES,
  TYPES,
} from '../../../components/form';
import { PARAMS as SELECT_PARAMS } from '../../../components/form/select';
import Dialog from '../../../components/dialog';
import MeetingDialog from './meetingDialog';
import { THEMES } from '../../../components/button';

import {
  getUTCDate,
  getTimeRange,
  getTimeRangeArr,
  isDatesEqual,
  isDateLater,
  isTimeIntervalsIntersect,
} from '../../../utils/time';
import { getById } from '../../../utils';
import { CALCULATED_CLIENT_STRUCT } from '../../../store/struct/calculated/client';
import StatusText from '../../../components/status-text/status-text';

import styles from './index.module.scss';
import commonStyles from '../../../styles/common.module.scss';

const dateFormat = new Intl.DateTimeFormat(undefined, {
  day: 'numeric',
  weekday: 'long',
  month: 'long',
});
const HEADERS = ['Учреждение', 'Должность', 'ФИО', 'Статус', 'Встреча'];
export const INVEST_TABLE_HEADERS = [
  'Название',
  'Стоимость (у.е)',
  'Коэффициент',
  'Выбрать',
];
const KEY_NAME = 'key';
const DATE = 'date';
const TIME = 'time';

const MEETING_TABLE_STRUCT = {
  ...CALCULATED_CLIENT_STRUCT,
  MEETING_INDEX: 'meetingIndex',
};

const getEntryMeetingTime = entry => {
  const meeting =
    entry[MEETING_TABLE_STRUCT.MEETINGS][
      entry[MEETING_TABLE_STRUCT.MEETING_INDEX]
    ];
  const dateString = meeting[MEETING_STRUCT.START];

  return getUTCDate(dateString).getTime();
};

const getTimeslotDateStr = dateStr => {
  const date = getUTCDate(dateStr);
  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  return `${year}-${month > 8 ? '' : '0'}${month + 1}-${
    day > 9 ? '' : '0'
  }${day}`;
};

const getFirstAvailableOption = options => {
  for (let i = 0; i < options.length; i++) {
    if (!options[i][PARAMS.DISABLED]) {
      return options[i][PARAMS.ID];
    }
  }

  return null;
};

const Meetings = props => {
  const {
    user,
    game,
    dispatch,
    clients = [],
    meetings = [],
    timeslots = [],
  } = props;
  const [selectedClientForMeeting, setSelectedClientForMeeting] = useState(
    null,
  );
  const [selectedDateForMeeting, setSelectedDateForMeeting] = useState(null);
  const [selectedTimeForMeeting, setSelectedTimeForMeeting] = useState(null);
  const [selectedMeeting, setSelectedMeeting] = useState(null);
  const [meetingStarted, setMeetingStarted] = useState(true);
  const [currentMeeting, setCurrentMeeting] = useState(null);
  const [showTimeslotWarnig, setShowTimeslotWarning] = useState(false);

  if (selectedMeeting && !currentMeeting) {
    setMeetingStarted(false);
    setSelectedMeeting(null);
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSetSelectedMeeting = meetingData => {
    setSelectedMeeting(
      meetings.filter(
        val =>
          val[MEETING_STRUCT.CUSTOMER_ID] ===
          meetingData[MEETING_TABLE_STRUCT.ID],
      )[meetingData[MEETING_TABLE_STRUCT.MEETING_INDEX]],
    );
    setMeetingStarted(true);
  };
  useEffect(() => {
    const currMeeting = find(meetings, {
      [MEETING_STRUCT.STATUS]: MEETING_STATUSES.inProgress,
    });
    setCurrentMeeting(currMeeting);
    currMeeting &&
      currMeeting[CALCULATED_MEETING_STRUCT.REP_ACTUAL_START] &&
      setSelectedMeeting(currMeeting);
  }, [meetings, currentMeeting]);

  const closeDialog = useCallback(() => {
    setSelectedClientForMeeting(null);
    setSelectedTimeForMeeting(null);
  }, [setSelectedClientForMeeting]);
  const saveMeeting = useCallback(() => {
    const timeslot = getById(timeslots, selectedTimeForMeeting);

    if (!selectedTimeForMeeting) {
      closeDialog();
      return;
    }
    if (timeslot.meeting_id) {
      setShowTimeslotWarning(true);
    } else {
      const data = {
        timeslot_id: selectedTimeForMeeting,
        representative_id: user.id,
      };

      dispatch(createMeeting, data);
      closeDialog();
    }
  }, [selectedTimeForMeeting, timeslots, user, dispatch, closeDialog]);
  const timeslotWarningOnSubmit = useCallback(() => {
    closeDialog();
    setShowTimeslotWarning(false);
  }, [closeDialog]);
  const removeMeetingCb = useCallback(
    tableRowData => {
      const meetings = tableRowData[MEETING_TABLE_STRUCT.MEETINGS];
      const rowMeetingIndex = tableRowData[MEETING_TABLE_STRUCT.MEETING_INDEX];
      dispatch(removeMeeting, {
        timeslot_id: meetings[rowMeetingIndex][MEETING_STRUCT.ID],
      });
    },
    [dispatch],
  );
  const selectMeetingDate = useCallback(
    formData => {
      setSelectedDateForMeeting(formData[DATE]);
      setSelectedTimeForMeeting(formData[TIME]);
    },
    [setSelectedDateForMeeting],
  );
  const tableMeetingsConfig = useMemo(() => {
    return [
      {
        type: TABLE_TYPES.TEXT,
        getValue: tableRowData =>
          tableRowData[CALCULATED_CLIENT_STRUCT.INSTITUTION][
            INSTITUTION_STRUCT.NAME
          ],
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: tableRowData =>
          tableRowData[CALCULATED_CLIENT_STRUCT.POSITION][POSITION_STRUCT.NAME],
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: tableRowData => tableRowData[CALCULATED_CLIENT_STRUCT.NAME],
      },
      {
        type: TABLE_TYPES.TEXT,
        getValue: tableRowData => {
          const meetings = tableRowData[MEETING_TABLE_STRUCT.MEETINGS];
          const rowMeetingIndex =
            tableRowData[MEETING_TABLE_STRUCT.MEETING_INDEX];
          if (rowMeetingIndex === null) {
            return '-';
          }

          const status = meetings[rowMeetingIndex][MEETING_STRUCT.STATUS];
          if (status === MEETING_STATUSES.inProgress) {
            return <StatusText status={status} text={'Текущая'} />;
          }

          const time =
            meetings[rowMeetingIndex][CALCULATED_MEETING_STRUCT.TIME];
          const date =
            meetings[rowMeetingIndex][CALCULATED_MEETING_STRUCT.DATE];

          return <StatusText status={status} text={`${date} ${time}`} />;
        },
      },
      {
        type: TABLE_TYPES.CONDITIONAL,
        // returns index of the desired component from the components property
        getComponentIndex: tableRowData => {
          const meetingIndex = tableRowData[MEETING_TABLE_STRUCT.MEETING_INDEX];
          if (meetingIndex === null) {
            return 0;
          }

          const meetings = tableRowData[MEETING_TABLE_STRUCT.MEETINGS];
          const status = meetings[meetingIndex][MEETING_STRUCT.STATUS];

          const meetingStatusesMap = {
            [MEETING_STATUSES.done]: 3,
            [MEETING_STATUSES.inProgress]: 2,
            [MEETING_STATUSES.pending]: 1,
            [MEETING_STATUSES.canceled]: 3,
          };
          return meetingStatusesMap[status];
        },
        components: [
          {
            type: TABLE_TYPES.BUTTON,
            onClick: data => {
              setSelectedClientForMeeting(data[CLIENT_STRUCT.ID]);
              setSelectedDateForMeeting(null);
            },
            text: 'Назначить',
            theme: THEMES.PRIMARY,
            min: true,
            getProps: () => {
              const plannedMeetings = meetings.filter(
                meeting =>
                  meeting[MEETING_STRUCT.STATUS] ===
                    MEETING_STATUSES.inProgress ||
                  meeting[MEETING_STRUCT.STATUS] === MEETING_STATUSES.pending,
              ).length;
              const scheduledMeetings = game[GAME_STRUCT.SCHEDULED_MEETINGS];
              return {
                disabled: plannedMeetings >= scheduledMeetings,
              };
            },
          },
          {
            type: TABLE_TYPES.BUTTON,
            onClick: removeMeetingCb,
            text: 'Отменить',
            theme: THEMES.CANCEL,
            min: true,
          },
          {
            type: TABLE_TYPES.BUTTON,
            onClick: onSetSelectedMeeting,
            text: 'Заполнить',
            theme: THEMES.PRIMARY,
            min: true,
          },
          {
            type: TABLE_TYPES.TEXT,
            getValue: () => '',
          },
        ],
      },
    ];
  }, [removeMeetingCb, onSetSelectedMeeting, meetings, game]);
  const tableMeetingsHeaderConfig = useMemo(() => {
    return [
      {
        props: { className: commonStyles.headerSelect },
      },
      {
        props: { className: commonStyles.headerSelect },
      },
    ];
  }, []);
  const blockedTimesForSelectedDate = useMemo(() => {
    if (!selectedDateForMeeting) {
      return [];
    }
    const dateStr = getTimeslotDateStr(selectedDateForMeeting);

    return timeslots
      .filter(item => {
        if (
          !item[TIMESLOT_STRUCT.MEETING_ID] ||
          item[TIMESLOT_STRUCT.START].indexOf(dateStr) < 0
        ) {
          return false;
        }

        return find(
          meetings,
          meeting => meeting[MEETING_STRUCT.ID] === item[TIMESLOT_STRUCT.ID],
        );
      })
      .map(item =>
        getTimeRangeArr(item[TIMESLOT_STRUCT.START], item[TIMESLOT_STRUCT.END]),
      );
  }, [timeslots, meetings, selectedDateForMeeting]);
  const meetingsData = useMemo(() => {
    const endingEntries = [];
    const meetingEntries = clients.reduce((acc, client) => {
      const meetingsCount = client[CALCULATED_CLIENT_STRUCT.MEETINGS].length;

      for (let i = 0; i <= meetingsCount; i++) {
        const entry = {
          ...client,
          [KEY_NAME]: `${client[CLIENT_STRUCT.ID]}_${i}`,
          [MEETING_TABLE_STRUCT.MEETING_INDEX]: i < meetingsCount ? i : null,
        };

        // place records that allow to create meeting in the end of the table
        if (entry[MEETING_TABLE_STRUCT.MEETING_INDEX] !== null) {
          acc.push(entry);
        } else {
          endingEntries.push(entry);
        }
      }

      return acc;
    }, []);
    meetingEntries.sort(
      (entry1, entry2) =>
        getEntryMeetingTime(entry1) - getEntryMeetingTime(entry2),
    );

    return meetingEntries.concat(endingEntries);
  }, [clients]);
  const dateOptions = useMemo(() => {
    if (!selectedClientForMeeting) {
      return [];
    }
    const clientId = selectedClientForMeeting;

    return [
      { [SELECT_PARAMS.TITLE]: 'Выберите дату' },
      ...timeslots.reduce((acc, item) => {
        const startDate = getUTCDate(item[TIMESLOT_STRUCT.START]);
        const dateString = getTimeslotDateStr(startDate);
        const today = new Date();

        if (
          item[TIMESLOT_STRUCT.CUSTOMER_ID] !== clientId ||
          find(acc, { [SELECT_PARAMS.ID]: dateString })
        ) {
          return acc;
        } else {
          return acc.concat({
            [SELECT_PARAMS.ID]: dateString,
            [SELECT_PARAMS.TITLE]: dateFormat.format(startDate),
            [SELECT_PARAMS.DISABLED]:
              !isDateLater(startDate, today) && !isDatesEqual(startDate, today),
          });
        }
      }, []),
    ];
  }, [timeslots, selectedClientForMeeting]);
  const timeOptions = useMemo(() => {
    const titleOption = { [SELECT_PARAMS.TITLE]: 'Выберите время' };
    if (!selectedClientForMeeting || !selectedDateForMeeting) {
      return [titleOption];
    }
    const clientId = selectedClientForMeeting;
    const now = new Date();
    const selectedDate = selectedDateForMeeting;

    return [
      titleOption,
      ...timeslots.reduce((acc, item) => {
        const start = item[TIMESLOT_STRUCT.START];
        const startDate = getUTCDate(start);
        const end = item[TIMESLOT_STRUCT.END];
        const timeRangeArr = getTimeRangeArr(start, end);

        if (
          item[TIMESLOT_STRUCT.CUSTOMER_ID] !== clientId ||
          getTimeslotDateStr(startDate) !== selectedDate ||
          (selectedDate === getTimeslotDateStr(now) &&
            now.getTime() > startDate.getTime())
        ) {
          return acc;
        }

        return acc.concat({
          [SELECT_PARAMS.ID]: item[TIMESLOT_STRUCT.ID],
          [SELECT_PARAMS.TITLE]: getTimeRange(start, end),
          [SELECT_PARAMS.DISABLED]:
            item[TIMESLOT_STRUCT.MEETING_ID] ||
            blockedTimesForSelectedDate.some(blockedTimeRangeArr =>
              isTimeIntervalsIntersect(blockedTimeRangeArr, timeRangeArr),
            ),
        });
      }, []),
    ];
  }, [
    timeslots,
    selectedClientForMeeting,
    selectedDateForMeeting,
    blockedTimesForSelectedDate,
  ]);
  const meetingForm = useMemo(() => {
    if (!selectedClientForMeeting) {
      return null;
    }
    const data = find(meetingsData, { id: selectedClientForMeeting });
    const availableTimeOption = getFirstAvailableOption(timeOptions);
    const availableDateOption = getFirstAvailableOption(dateOptions);
    const selectedDate = selectedDateForMeeting || availableDateOption;

    return [
      {
        [PARAMS.ID]: DATE,
        [PARAMS.TITLE]: 'Дата встречи:',
        [PARAMS.INITIAL_VALUE]: selectedDate,
        [PARAMS.OPTIONS]: dateOptions,
        [PARAMS.TYPE]: TYPES.SELECT,
      },
      {
        [PARAMS.ID]: TIME,
        [PARAMS.TITLE]: 'Время встречи:',
        [PARAMS.INITIAL_VALUE]: availableTimeOption,
        [PARAMS.OPTIONS]: timeOptions,
        [PARAMS.TYPE]: TYPES.SELECT,
      },
      {
        [PARAMS.ID]: CALCULATED_CLIENT_STRUCT.NAME,
        [PARAMS.TITLE]: 'Фамилия Имя:',
        [PARAMS.TYPE]: FORM_TYPES.INPUT,
        [PARAMS.INITIAL_VALUE]: data[CALCULATED_CLIENT_STRUCT.NAME],
        [PARAMS.READONLY]: true,
      },
      {
        [PARAMS.ID]: CALCULATED_CLIENT_STRUCT.POSITION,
        [PARAMS.TITLE]: 'Должность:',
        [PARAMS.TYPE]: FORM_TYPES.INPUT,
        [PARAMS.INITIAL_VALUE]: `${
          data[CALCULATED_CLIENT_STRUCT.POSITION][POSITION_STRUCT.NAME]
        }, ${data[CALCULATED_CLIENT_STRUCT.INSTITUTION][
          POSITION_STRUCT.NAME
        ].toLowerCase()}`,
        [PARAMS.READONLY]: true,
      },
      {
        [PARAMS.ID]: POSITION_STRUCT.DESC,
        [PARAMS.TITLE]: 'Полномочия:',
        [PARAMS.TYPE]: FORM_TYPES.INPUT,
        [PARAMS.INITIAL_VALUE]: data[POSITION_STRUCT.DESC],
        [PARAMS.READONLY]: true,
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    meetingsData,
    selectedClientForMeeting,
    selectedDateForMeeting,
    dateOptions,
  ]);
  const meetingsTable = useMemo(() => {
    return clients.length ? (
      <Table
        className={styles.tableFullHeight}
        headers={HEADERS}
        config={tableMeetingsConfig}
        headerConfig={tableMeetingsHeaderConfig}
        keyName={KEY_NAME}
        data={meetingsData}
        filters={[0, 1]}
      />
    ) : null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    clients,
    tableMeetingsConfig,
    tableMeetingsHeaderConfig,
    meetingsData,
    meetings,
  ]);
  const dateTimeForm = useMemo(() => {
    return (
      meetingForm && (
        <Form
          config={meetingForm}
          onChange={selectMeetingDate}
          className={styles.customStartMeeting}
        />
      )
    );
  }, [meetingForm, selectMeetingDate]);
  const timeslotWarning = useMemo(() => {
    return <div>Выбранное время уже занято</div>;
  }, []);

  const onMeetingEnd = useCallback(
    ({ prescriptionFormResult, gradesState, comment, status }) => {
      const grades = {};
      forOwn(gradesState, (val, key) => {
        grades[key] = gradesState[key];
      });
      dispatch(sendRepMeetingResult, {
        [RESULT_STRUCT.MEETING]: {
          id: selectedMeeting[MEETING_STRUCT.MEETING_ID],
        },
        [RESULT_STRUCT.PRESCRIPTION]: JSON.stringify(prescriptionFormResult),
        [RESULT_STRUCT.GRADES]: grades,
        [RESULT_STRUCT.COMMENT]: comment,
        [RESULT_STRUCT.STATUS]: status,
      });
      setMeetingStarted(false);
      setCurrentMeeting(null);
    },
    [dispatch, selectedMeeting],
  );

  const onMeetingStart = useCallback(() => {
    dispatch(updateMeeting, {
      id: selectedMeeting[MEETING_STRUCT.MEETING_ID],
      timeslot_id: selectedMeeting[MEETING_STRUCT.ID],

      startedRep: true, // send flag of starting
    });
  }, [dispatch, selectedMeeting]);

  return (
    <>
      <div className={styles.container}>{meetingsTable}</div>
      {selectedClientForMeeting && (
        <Dialog
          title='Назначение встречи'
          onSubmit={showTimeslotWarnig ? timeslotWarningOnSubmit : saveMeeting}
          submitTitle={showTimeslotWarnig ? 'Закрыть' : 'Сохранить'}
          onCancel={!showTimeslotWarnig ? closeDialog : null}
          submitDisabled={!selectedTimeForMeeting}
        >
          {showTimeslotWarnig ? timeslotWarning : dateTimeForm}
        </Dialog>
      )}
      {selectedMeeting && meetingStarted && (
        <MeetingDialog
          onEnd={onMeetingEnd}
          clientId={selectedMeeting[CALCULATED_MEETING_STRUCT.CUSTOMER_ID]}
          meetingStartTime={selectedMeeting[CALCULATED_MEETING_STRUCT.TIME]}
          meetingStartDate={selectedMeeting[CALCULATED_MEETING_STRUCT.DATE]}
          meetingStart={selectedMeeting[TIMESLOT_STRUCT.START]}
          onStart={onMeetingStart}
          onCancelStart={() => setSelectedMeeting(null)}
          actualStart={selectedMeeting[MEETING_STRUCT.ACTUAL_START]}
          meetingStatus={selectedMeeting[MEETING_STRUCT.STATUS]}
          isMeetingStarted={
            selectedMeeting[CALCULATED_MEETING_STRUCT.REP_ACTUAL_START]
          }
        />
      )}
    </>
  );
};

export default branch(
  {
    user: meSelector(),
    timeslots: timeslotsSelector(),
    meetings: agentMeetingsSelector(),
    clients: currentClientsSelector(),
    game: currentGameSelector(),
  },
  Meetings,
);
